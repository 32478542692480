/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isEmpty } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            unitInMiles: false,
        };
    }

    componentDidMount() {
        const { uomPreference } = this.props;

        if (uomPreference === 'british-imperial') {
            this.setState({ unitInMiles: true });
        }
    }

    setUnit = (e) => {
        this.setState({ unitInMiles: e?.key === 'imperial' });
    };

    render() {
        const {
            trueOdometerReport, isFetching, uomPreference, reportHasBeenFetched,
        } = this.props;

        const { unitInMiles } = this.state;

        const formattedReports = trueOdometerReport.map((report) => {
            const KM_TO_MILES_FACTOR = 1.609344;
            let { start_odometer } = report;
            let { end_odometer } = report;
            let mileage_difference = report.mileage_difference_in_km;
            if (unitInMiles) {
                if (start_odometer) {
                    start_odometer = `${parseFloat(start_odometer / KM_TO_MILES_FACTOR).toFixed(2)}`;
                    start_odometer = `${start_odometer}`;
                } else start_odometer = '-';
                if (end_odometer) {
                    end_odometer = `${parseFloat(end_odometer / KM_TO_MILES_FACTOR).toFixed(2)}`;
                    end_odometer = `${end_odometer}`;
                } else end_odometer = '-';
                if (mileage_difference) {
                    mileage_difference = `${parseFloat(mileage_difference / KM_TO_MILES_FACTOR).toFixed(2)}`;
                    mileage_difference = `${mileage_difference}`;
                } else mileage_difference = '-';
            } else {
                if (start_odometer) start_odometer = `${parseFloat(start_odometer).toFixed(2)}`;
                else start_odometer = '-';
                if (end_odometer) end_odometer = `${parseFloat(end_odometer).toFixed(2)}`;
                else end_odometer = '-';
                if (mileage_difference) mileage_difference = `${parseFloat(mileage_difference).toFixed(2)}`;
                else mileage_difference = '-';
            }
            const formattedReport = {
                ...report,
                start_odometer,
                end_odometer,
                mileage_difference,
            };
            delete formattedReport.mileage_difference_in_km;
            delete formattedReport.key;
            return formattedReport;
        });
        if (formattedReports.lenght) formattedReports.push({});
        const exportableColumns = formattedReports && formattedReports[0] ? (Object.keys(formattedReports[0]).filter((oK) => oK.toLowerCase() != 'actions')) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(formattedReports)
                        ? reportHasBeenFetched
                            ? <CustomEmpty />
                            : <CustomEmpty type={CustomEmptyType.Report} />
                        : (
                            <>
                                <br />
                                <Dropdown overlay={(
                                    <Menu onClick={this.setUnit}>
                                        <Menu.Item key="imperial">Miles</Menu.Item>
                                        <Menu.Item key="metric">Kilometers</Menu.Item>
                                    </Menu>
                                )}>
                                    <Button 
                                        type='primary'
                                        className='export-dropdown-button'
                                        style={{ 
                                            right: 96, 
                                            top: -14 
                                        }}
                                    >
                                        {unitInMiles ? 'Miles' : 'Kilometers'}
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                                <GridView
                                    showExportButton
                                    exportableColumns={exportableColumns}
                                    hiddenColumns={['id', 'division_id']}
                                    data={formattedReports}
                                    onChange={this.onTableChange}
                                    disablePagination />
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    trueOdometerReport: [],
};

ReportTab.propTypes = {
    trueOdometerReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    uomPreference: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        trueOdometerReport: state.report.trueOdometerReport,
        isFetching: state.report.isFetching,
        uomPreference: state.user.profile.unit_of_measurement_preference,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
