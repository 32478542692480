/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { AutoComplete, Input, Tree, Button } from 'antd';
import Icon from '../elements/Icon';

import './assetSearchTree.scss';

const { Search } = Input;
const { Option } = AutoComplete;

export default function () {
    const { assets, backLinkClick, actualCheckedAssets, totalAssets } = this.props;
    let {
        expandedKeys, checkedKeys, autoExpandParent,
    } = this.state;

    const treeData = this.populateTree(assets);
    if (expandedKeys && expandedKeys.length) expandedKeys = [
        'select-all',
        ...expandedKeys,
    ];

    return (
        <>
            <div className="map-filter-search">
                <Button 
                    className="btn btn-empty" 
                    onClick={backLinkClick}
                    style={{
                        display: 'inline-block',
                        width: '32px',
                        height: '32px',
                    }}
                >
                    <Icon 
                        name="arrow-left-lg"
                        style={{
                            width: '32px',
                            height: '32px',
                        }} 
                    />
                </Button>
                <Search
                    ref={this.searchBox}
                    placeholder="Search Name/Reg/Driver"
                    onChange={this.onChange} 
                    className='asset-search-tree__search-bar'
                />
            </div>
            <Tree
                checkable
                showIcon
                showLine
                className="hide-file-icon"
                onExpand={this.onExpand}
                expandedKeys={expandedKeys && expandedKeys.length ? expandedKeys : ['select-all']}
                autoExpandParent={autoExpandParent}
                onCheck={this.onCheck}
                onSelect={(selectedKeys, e) => this.onLeafSelect(selectedKeys, e, treeData)}
                checkedKeys={checkedKeys.filter((item) => item && item !== '999')}
                treeData={this.loop(treeData)} 
            />
        </>
    );
}
