/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    Spin,
    Button,
    Modal,
    Menu,
    Tree,
    Switch,
} from 'antd';
import moment from 'moment';
import {
    isEmpty, debounce, map, isArray,
} from 'underscore';
import Map from '../../components/Map';
import Layout from '../../components/layout/Layout';
import '../../components/Filter/filter.scss';
import AssetsSearchSidebar from './forms/AssetsSearchSidebar';
import { assignPopUpsToMapPoints, openStreetView } from '../../core/utils/functions';
import { mapHeatMapMarkers, requestMarkerDetails } from '../../core/utils/mapUtils';
import { MAP_MODE_DOTS } from '.';

import './map.scss';

const { GOOGLE_MAP_API_KEY, MAP_SLIDER_INTERVAL } = require('../../core/constants').default;

export default function () {
    const {
        selectedDate, selectedTime, showSidebar, showDeviceSidebar, showAssetsSidebar, selectedAssetLocation, assetDistanceFromCentre,
        selectedAsset, showEditTrigger, zoom, mapLiveAssets, isMapLive, singleTrigger, centreMapLatLong,
        checkedAssets, toggleShowGeoFence, drawGeoFence, currentHoveredAsset, markerPopover, checkedHeatmapKeys,
        mapMode, timesheetReport, device, executeAutoZoom, googleMapTilesLoaded, selectedAssetIsLoading,
    } = this.state;

    const {
        isFetching, mapSelectedDeviceInfo, userMapCenter, mapDeviceHeatMap, divisions, allTriggers, actions,
        selectedSliderValue, mapDate, user, mapSelectedDeviceLocations, mapDevices, mapDevicesLastLocation,
        mapSelectedDeviceTelematicsData, selectedAssets, mapDeviceLocationRequest, mapDeviceListIsFetching,
        selectedDeviceTimeline, selectedDeviceGeofenceTimeline, isExportingCsv,deviceDetailedLocationInfo,
    } = this.props;

    const geofenceMenu = (
        <Menu>
            <Menu.Item onClick={this.toggleGeoFence} disabled={!this.state.hasGeoFenceReportEnabled}>Toggle geo-fence</Menu.Item>
            <Menu.Item onClick={this.toggleMapGeofence} disabled={!this.state.hasGeoFenceReportEnabled}>Search geo-fence</Menu.Item>
        </Menu>
    );

    let localShowHeatMap = false;
    if (this.googleMapAccess && this.googleMapAccess.current && this.googleMapAccess.current.state) {
        localShowHeatMap = this.googleMapAccess.current.state.showHeatMap;
    }
    const heatMapMenu = () => {
        const { eventTypes } = this.props;
        const children = [];
        const checkedChildren = [];

        Object.entries(eventTypes).forEach(([index, value]) => {
            const { id, key } = value;
            const eventType = {
                title: key,
                key: id,
            };
            if (checkedHeatmapKeys.includes(id)) checkedChildren.push(id);
            children.push(eventType);
        });

        const treeData = [
            {
                title: 'All',
                key: 'all',
                children,
            },
        ];

        return (
            <Menu style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                overflowY: 'scroll',
                maxHeight: '600px',
            }}>
                <Tree
                    defaultExpandedKeys={['all']}
                    defaultCheckedKeys={checkedChildren}
                    checkable
                    onCheck={this.refreshHeatmap}
                    treeData={treeData} />
            </Menu>
        );
    };


    const mapToolBarButtons = [
        {
            key: 'side-bar',
            onClick: this.sidebarToolBarButtonOnclick,
        },
        {
            key: 'geo-fence', onClick: () => { },
            menu: geofenceMenu,
        },
        {
            key: 'heat-map',
            onClick: () => { }, menu: heatMapMenu ,
        },
        {
            key: 'live-map',
            onClick: this.toggleLiveView ,
        },
        {
            key: 'info-box',
            onClick: this.toggleInfoBox,
            defaultSelected: !!user.profile?.show_info_preference,
        },
    ];

    let markers = mapLiveAssets;
    const newMapDevicesLastLocation = [];
    if (mapDevicesLastLocation && isArray(mapDevicesLastLocation)) {
        mapDevicesLastLocation.forEach((element) => {
            const newElement = element;
            newElement.lastLocation = true;
            newMapDevicesLastLocation.push(newElement);
        });
    }

    const mergedMapDevices = mapDevices.concat(mapDevicesLastLocation);

    let assets = assignPopUpsToMapPoints(
        mergedMapDevices,
        user,
        mapDeviceListIsFetching,
        isMapLive,
        drawGeoFence,
        currentHoveredAsset,
        timesheetReport,
        isExportingCsv,
        selectedDate,
        actions,
        deviceDetailedLocationInfo,
    );
    const mappedDevicePath = this.getMappedDevicePath();

    // parse out the asset ids - we only want to show the assets which have been checked from the asset search tree
    if (!isEmpty(checkedAssets) && checkedAssets[0] !== '999') {
        const assetIds = [];
        checkedAssets.map((item, key) => {
            const string = item.split('-');
            if (string[0] === 'asset') {
                const asset_id = item.split('-')[2];
                assetIds.push(asset_id);
            }
        });
        markers = mapLiveAssets.filter(({ asset_id }) => assetIds.includes(asset_id.toString()));
        assets = assets.filter(({ asset_id }) => assetIds.includes(asset_id.toString()));
    }
    if (!isEmpty(selectedAssets)) {
        markers = mapLiveAssets.filter(({ asset_id }) => selectedAssets.includes(asset_id));
        assets = assets.filter(({ asset_id }) => selectedAssets.includes(asset_id));
    }

    if (selectedAsset != null) {
        assets = this.getMappedDevicePathAsset(assets);
    }

    if (isEmpty(checkedAssets)) {
        markers = [];
        assets = [];
    }

    const triggers = allTriggers ? allTriggers.map((trigger) => ({ id: trigger.id, coordinates: trigger.trigger_points.map((point) => ({ lat: parseFloat(point.lat), lng: parseFloat(point.lng), id: trigger.id })) })) : [];

    if (toggleShowGeoFence) {
        assets = [];
        markers = [];
    }
    // Show only live assets or not live assets at one time
    if (isMapLive) {
        assets = [];
    } else {
        markers = [];
    }

    const liveAssetsForSearch = mapLiveAssets.map(({
        asset_id, name, division, division_id, device_status, imei, lat, lng, time, record_id,
    }) => ({
        asset_id, name, division, division_id, device_status, imei, lat, lng, time, record_id,
    }));

    const assetsSidebar = (
        <AssetsSearchSidebar
            isMapLive={isMapLive}
            selectedDate={selectedDate}
            checkedAssets={checkedAssets}
            backLinkClick={this.closeAssetsSearchSidebar}
            centreMap={this.centreMap}
            assetOnMouseOver={this.assetOnMouseOver}
            assetOnMouseOut={this.assetOnMouseOut}
            updateMapFromSearchTree={this.updateMapFromSearchTree}
            mapLiveAssets={liveAssetsForSearch}
            googleMapAccess={this.googleMapAccess} 
            selectedAsset={selectedAsset}
        />
    );
    let heatMap = {};
    if (localShowHeatMap === true) {
        heatMap = {
            positions: mapDeviceHeatMap ? mapDeviceHeatMap.map((mapDeviceHeatMapItem) => ({
                lat: parseFloat(mapDeviceHeatMapItem.latitude),
                lng: parseFloat(mapDeviceHeatMapItem.longitude),
                weight: parseInt(mapDeviceHeatMapItem.weight, 10) * 10,
            })) : [],
            options: {
                radius: 10,
                opacity: 0.6,
            },
        };
        // if ((mapLiveAssets && mapLiveAssets.length <= 0) && zoom >= 10) {
        if (zoom >= 10) {
            const heatMapMarkers = mapHeatMapMarkers(mapDeviceHeatMap, this.googleMapAccess);
            markers = markers.concat(heatMapMarkers);
        }
    }
    const MINUTES_IN_A_DAY = 1440;
    const SECONDS_IN_A_DAY = 86400;
    const maxSliderValue = selectedAsset
        ? ((SECONDS_IN_A_DAY / 10) - 1)
        : ((MINUTES_IN_A_DAY / (MAP_SLIDER_INTERVAL || 10)) - 1);
    const isLoading =
        (isFetching && this.mapUpdateInterval == null) ||
        mapDeviceLocationRequest ||
        executeAutoZoom ||
        !googleMapTilesLoaded ||
        mapDeviceListIsFetching ||
        selectedAssetIsLoading;
    const HEADER_HEIGHT = '113.5px';
    const MAP_TOOLBAR_HEIGHT = '40px';
    const height = `calc(100vh - ${HEADER_HEIGHT} - ${MAP_TOOLBAR_HEIGHT})`;

    return (
        <Layout
            data-test="pages-map"
            className="map-page"
            title={<div>Map</div>}
            headerTitle={<div>Map</div>}
            menuWidth={350}
            menuPlacement="left"
        >
            <div>
                <div className="content">
                    <Spin
                        size="large"
                        spinning={isLoading}
                    >
                        <div style={{ 
                            display: 'flex', 
                            height, 
                            flexDirection: 'row' 
                        }}>
                            <Map
                                ref={this.googleMapAccess}
                                hideSlider={false}
                                onChange={this.onMapChange}
                                markerOnClick={this.markerOnClick}
                                markerOnMouseEnter={(marker) => requestMarkerDetails(marker, this)}
                                markerOnMouseLeave={() => { }}
                                markerPopover={markerPopover}
                                pathInfoOnClick={this.pathInfoOnClick}
                                onDateChange={this.mapOnDateChange}
                                apiKey={GOOGLE_MAP_API_KEY}
                                geFenceCompleted={this.geFenceCompleted}
                                mapShapeOnClick={this.polygonClick}
                                mapToolBarButtons={mapToolBarButtons}
                                onPlacesChanged={(center) => actions.setUserMapCenter(center)}
                                zoom={zoom}
                                autoZoom
                                center={userMapCenter}
                                heatMap={heatMap}
                                showPathAsLine={mapMode === MAP_MODE_DOTS ? false : (mappedDevicePath !== null)}
                                path={mappedDevicePath}
                                polygonBoundary={triggers}
                                sliderStep={selectedAsset ? 10 : (MAP_SLIDER_INTERVAL || 10)}
                                sliderMaxValue={maxSliderValue}
                                sliderValueUnit={selectedAsset ? "seconds" : "minutes"}
                                sliderValue={parseInt(selectedSliderValue)}
                                mapDate={moment(mapDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                onSliderChange={debounce(this.onSliderValueChange, 500)}
                                getMapSearchBoundary={this.getMapSearchBoundary}
                                markers={markers}
                                assets={assets}
                                selectedAsset={selectedAsset}
                                currentHoveredAsset={currentHoveredAsset}
                                showNoAssetSelectedWarning
                                selectedDeviceTimeline={selectedDeviceTimeline}
                                selectedDeviceGeofenceTimeline={selectedDeviceGeofenceTimeline}
                                noLimit={true}
                                deviceDetailedLocationInfo={deviceDetailedLocationInfo}
                                mapMode={mapMode}
                                centreMapLatLong={centreMapLatLong}
                                enableOfflineMode={this.enableOfflineMode}
                                pathGeneratedCallback={this.pathGeneratedCallback}
                                onTilesLoaded={() => this.setState({ googleMapTilesLoaded: true })}
                            >
                                {showSidebar && showAssetsSidebar ? assetsSidebar : null}
                            </Map>
                        </div>
                    </Spin>
                </div>
            </div>
        </Layout>
    );
}
