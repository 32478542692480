/* global document:true */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Col, Button,
} from 'antd';
import moment from 'moment';
import { ExpandAltOutlined } from '@ant-design/icons';

import DOM from './trimStitchedVideo';
import * as videoActions from '../../../../core/video/videoActions';

class TrimStitchedVideo extends PureComponent {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            currentVideoIndex: 0,
            middleFinalTime: 0,
            start: 0,
            middle: 0,
            end: 100,
            hideVideos: false,
            activeVideo: null,
            selectedVideos: [],
        };

        this.videosRefs = [];
        this.timeout = 0;
    }

    componentDidMount() {
        const { actions, video } = this.props;

        actions.getStitchedVideosRequest({
            asset_id: video?.searchedSingleVideo?.asset_id,
            date: video?.searchedSingleVideo?.date_from,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.video.searchedSingleVideo !== prevProps.video.searchedSingleVideo) {

        }
    }

    renderVideo(selectedVideo, chanelKey) {
        const { currentVideoIndex, activeVideo } = this.state;
        const loading = false;

        let thisVideoUrl = '';
        const videoId = `${chanelKey}_${currentVideoIndex}`;
        if (selectedVideo && selectedVideo.aws_url) {
            thisVideoUrl = selectedVideo.aws_url;
        }

        let extraClass = '';
        if (chanelKey == activeVideo) {
            extraClass = 'activeVideo';
        }

        return (
            <Col
                className="grid-video"
                key={`camera-${videoId}`}
                style={{
                    overflow: 'hidden',
                    width: '50%',
                }}>

                <div className={`singleVideo ${extraClass}`}>
                    <Button
                        className="expandButton"
                        type="link"
                        onClick={() => {
                            this.zoomVideo(chanelKey);
                        }}>
                        <ExpandAltOutlined style={{ color: 'var(--cool-gray-80)', fontSize: '20px' }} />
                    </Button>
                    { /* video.aws_url_converted ? <VideoPayer identity={video.id} url={video.aws_url_converted} /> : null */ }

                    {thisVideoUrl ? (
                        <div>
                            <video autoPlay width="100%">
                                <source src={thisVideoUrl} type="video/mp4" />
                            </video>
                        </div>
                    )
                        : null}
                </div>
            </Col>
        );
    }

    renderStitchedVideoButton(stitchedVideos, key) {
        let singleStitchedVideo = null;
        if (stitchedVideos && stitchedVideos[0]) {
            singleStitchedVideo = stitchedVideos[0];
        }

        let timeFrom = '';
        let timeTo = '';
        let extraClass = '';

        if (singleStitchedVideo && singleStitchedVideo.start_time) {
            timeFrom = moment.unix(singleStitchedVideo?.start_time).format('HH:mm:ss');
        }

        if (singleStitchedVideo && singleStitchedVideo.end_time) {
            timeTo = moment.unix(singleStitchedVideo?.end_time).format('HH:mm:ss');
        }

        if (singleStitchedVideo && singleStitchedVideo.status && singleStitchedVideo.status != 'cached') {
            extraClass = 'disabled';
        }

        return (
            <Button
                key={`stitchedVideoButton-${key}`}
                className={`stitchedVideoButton ${extraClass}`}
                onClick={() => {
                    this.selectActiveVideos(stitchedVideos);
                }}>
                <span className="stitchedVideoButtonText">Video {timeFrom} - {timeTo} {singleStitchedVideo.status}</span>
            </Button>
        );
    }

    selectActiveVideos(selectedVideos) {
        this.setState({
            selectedVideos,
        });
    }

    zoomVideo(chanelKey) {
        let { activeVideo } = this.state;

        if (chanelKey === activeVideo) {
            activeVideo = null;
        } else {
            activeVideo = chanelKey;
        }
        this.setState({ activeVideo });
    }

    render() {
        return this.view();
    }
}

TrimStitchedVideo.defaultProps = {
    setShouldShowSidebar: () => null,
};

TrimStitchedVideo.propTypes = {
    actions: PropTypes.object.isRequired,
    video: PropTypes.object.isRequired,
    setShouldShowSidebar: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        video: state.video,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...videoActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrimStitchedVideo);
