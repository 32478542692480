import React from 'react';

import Report from './components/report';
import Layout from '../../components/layout/Layout';
import AverageMPG from '../../assets/images/average_mpg.png';
import DrivingTime from '../../assets/images/driving_time.png';
import Mileage from '../../assets/images/mileage.png';
import CarbonReportSVG from '../../assets/icons/CarbonReportSVG';
import DrivingTimeSVG from '../../assets/icons/DrivingTimeSVG';
import IdleSVG from '../../assets/icons/IdleSVG';
import MileageSVG from '../../assets/icons/MileageSVG';
import MpgSVG from '../../assets/icons/MpgSVG';
import PtoSVG from '../../assets/icons/PtoSVG';
import SpeedingSVG from '../../assets/icons/SpeedingSVG';
import TimesheetSVG from '../../assets/icons/TimesheetSVG';
import TrueIdleSVG from '../../assets/icons/TrueIdleSVG';
import UserOutlinedSVG from '../../assets/icons/UserOutlinedSVG';
import DriverBehaviourSVG from '../../assets/icons/DriverBehaviourSVG';
import { Empty } from 'antd';

import './reports.scss';
import EventReportingSVG from '../../assets/icons/EventReportingSVG';
import GeoFencingSVG from '../../assets/icons/GeoFencingSVG';
import CustomEmpty from '../../components/CustomEmpty';

export default function () {
    const { company, user } = this.props;

    const allLinks = [
        {
            id: 1,
            link: 'mileage-report',
            title: 'Mileage Report',
            description: 'Generate a mileage report for selected assets or drivers.',
            image: Mileage,
            icon: MileageSVG,
        },
        {
            id: 2,
            link: 'fuel-report',
            title: 'Fuel Report',
            description: 'Generate report for selected assets or drivers. You can select from MPG, fuel volume or fuel cost.',
            image: AverageMPG,
            icon: MpgSVG,
        },
        {
            id: 3,
            link: 'driving-time-report',
            title: 'Driving Time/Vehicle usage time report',
            description: 'Generate a driving time/vehicle usage time report for selected assets or drivers.',
            image: DrivingTime,
            icon: DrivingTimeSVG,
        },
        {
            id: 4,
            link: 'idle-report',
            title: 'Idle Report',
            description: 'Generate an idle report for selected assets or drivers.',
            image: DrivingTime,
            icon: IdleSVG,
        },
        {
            id: 5,
            link: 'pto-report',
            title: 'PTO Report',
            description: 'Generate a pto report for selected assets or drivers.',
            image: DrivingTime,
            icon: PtoSVG,
        },
        {
            id: 6,
            link: 'true-idle-report',
            title: 'True Idle Report',
            description: 'Generate a true idle report for selected assets or drivers.',
            image: DrivingTime,
            icon: TrueIdleSVG,
        },
        {
            id: 7,
            link: 'full-pto-idle-report',
            title: 'PTO, idle, & True Idle Report',
            description: 'Generate a report for selected dates showing a breakdown of PTO Idle & True Idle across all records.',
            image: DrivingTime,
            icon: IdleSVG,
        },
        {
            id: 8,
            link: 'timesheet-report',
            title: 'Timesheet Report',
            description: 'Generate a timesheet report for an asset or driver.',
            image: DrivingTime,
            icon: TimesheetSVG,
        },
        {
            id: 9,
            link: 'speeding-report',
            title: 'Speeding Report',
            description: 'Generate a speeding report for an asset.',
            image: SpeedingSVG,
            icon: SpeedingSVG,
        },
        {
            id: 10,
            link: 'carbon-report',
            title: 'Carbon Report',
            description: 'Generate a carbon report for an asset.',
            image: null,
            icon: CarbonReportSVG,
        },
        {
            id: 11,
            link: 'unauthorised-journey-report',
            title: 'Unauthorised Journey Report',
            description: 'Generate an unauthorised journey report.',
            icon: UserOutlinedSVG,
        },
        {
            id: 12,
            link: 'asset-odometer-report',
            title: 'Asset Odometer Report',
            description: 'Generate a mileage report based upon device odometer.',
            icon: MileageSVG,
        },
        {
            id: 13,
            link: 'utilisation-report',
            title: 'Utilisation Report',
            description: 'Generate a utilisation report for assets or drivers.',
            image: DrivingTime,
            icon: DrivingTimeSVG,
        },
        {
            id: 14,
            link: 'eco-score-report',
            title: 'Eco Score Report',
            description: 'Generate Eco Score report',
            icon: DriverBehaviourSVG,
        },
        {
            id: 15,
            link: 'event-report',
            title: 'Event Report',
            description: 'Generate Event report',
            icon: EventReportingSVG,
        },
        {
            id: 16,
            link: 'geofence-report',
            title: 'Geo-fence Entry/Exit Report',
            description: 'Generate Geo-fence Entry/Exit report',
            icon: GeoFencingSVG,
        },
        {
            id: 17,
            link: 'outside-working-hours-report',
            title: 'Inside/Outside Working Hours Report',
            description: 'Generate Inside/Outside working outside hours report',
            icon: DriverBehaviourSVG,
        },
        {
            id: 18,
            link: 'not-moved-report',
            title: 'Not Moved Report',
            description: 'Generate Not Moved report',
            icon: TrueIdleSVG,
        }
    ];
    const links = [];
    if (Array.isArray(company.features)) {
        allLinks.forEach((item) => {
            if (company.features.find(feature => item.link == feature.id) || item?.allow == true) {
                links.push(item);
            }
        })
    }

    const clearParams = () => {
        const { actions } = this.props;
        actions.setDataEventToReportParams(null);
    }


    const items = links.map((l) => (
        <Report
            key={l.id}
            icon={l.icon}
            link={l.link}
            title={l.title}
            description={l.description}
            clearParams={clearParams.bind(this)}
            image={l.image} />
    ));

    let display = (
        <div>
            <br />
            <br />
            <CustomEmpty />
        </div>
    );
    if (items.length) {
        display = (<div className="links-container">
            {items}
        </div>);
    }

    return (
        <Layout
            className="reports"
            title={<div>Reports</div>}
            headerTitle={<div>Reports Page</div>}
            showFooter={false}
        >
            <div className={`content-wrap${user?.userCompany?.currentAlert ? '-with-banner' : ''}`}>
                {display}
            </div>
        </Layout>
    );
}
