import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Spin, Button, Row, Col, Popconfirm,
} from 'antd';
import GridView from '../../../components/elements/GridView/index';
import CreateServiceAlertForm from '../forms/createServiceAlertForm';
import * as assetActions from '../../../core/asset/assetActions';
import * as userActions from '../../../core/user/userActions';
import Icon from '../../../components/elements/Icon';

class AssetServiceAlertsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateAlertForm: false,
            serviceAlert: {
                id: null,
                name: null,
                description: null,
                service_date: null,
                frequency: null,
                notify_user: null,
                frequency_desc: null,
                user_id: null,
                alert_type: 0,
            },
        };
    }

    componentDidMount() {
        const {
            actions, setLoadedState, serviceAlertsTabLoaded, assetId,
        } = this.props;
        actions.getCompanyUsersRequest();
        if (!serviceAlertsTabLoaded) {
            actions.getAssetServiceAlertsRequest({
                assetId,
                page: 1,
                alert_type: 0,
            });
            setLoadedState();
        }
    }

    clearForm = () => {
        this.setState({
            showCreateAlertForm: false,
            serviceAlert: {
                id: null,
                name: null,
                description: null,
                service_date: null,
                frequency: null,
                frequency_desc: null,
                notify_user: null,
                user_id: null,
            },
        });
    };

    createForm = () => {
        this.setState({
            showCreateAlertForm: false,
            serviceAlert: {
                id: null,
                name: null,
                description: null,
                service_date: null,
                frequency: null,
                frequency_desc: null,
                notify_user: null,
                user_id: null,
            },
        });

        setTimeout(() => this.setState({
            showCreateAlertForm: true,
        }), 1);
    };

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange, serviceAlertsPagination } = this.props;

        if (pagination && pagination.current === serviceAlertsPagination.currentPage) {
            onTableChange(pagination, filters, sorter, extra);
        }
    };

    createServiceAlert = (values) => {
        const { actions, assetId } = this.props;
        const { serviceAlert } = this.state;
        const val = values;
        if (val.service_date) {
            val.service_date = moment(val.service_date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        actions.createServiceAlertRequest({
            alertId: serviceAlert.id || 0,
            assetId,
            ...val,
        });
        this.setState({ showCreateAlertForm: false });
    };

    deleteServiceAlert = (id) => {
        const { actions, assetId } = this.props;
        actions.deleteServiceAlertRequest({
            alertId: id,
            assetId,
        });
        this.setState({ showCreateAlertForm: false });
    };

    render() {
        const {
            serviceAlerts, serviceAlertsPagination, isFetching,
        } = this.props;
        const { showCreateAlertForm, serviceAlert } = this.state;
        const listAssetServiceAlerts = serviceAlerts.map((assetAlert) => {
            const tempAssetAlert = {};
            tempAssetAlert.alert_name = assetAlert.name;
            tempAssetAlert.alert_description = assetAlert.description;
            tempAssetAlert.next_alert = moment(assetAlert.service_date, 'YYYY-MM-DD').add(1, 'years').format('DD/MM/YYYY');
            tempAssetAlert.frequency = assetAlert.frequency_desc;
            tempAssetAlert.recipient_user = assetAlert.user_email;
            tempAssetAlert.actions = (
                <div>
                    <a
                        href="#"
                        className="btn btn-empty ml-2"
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                                showCreateAlertForm: true,
                                serviceAlert: {
                                    id: assetAlert.id,
                                    name: assetAlert.name,
                                    description: assetAlert.description,
                                    service_date: assetAlert.service_date,
                                    frequency: assetAlert.frequency,
                                    frequency_desc: assetAlert.frequency_desc,
                                    notify_user: assetAlert.user_email,
                                    user_id: assetAlert.user_id,
                                },
                            });
                        }}>
                        <Icon name="pencil" />
                    </a>
                    <Popconfirm
                        onConfirm={() => this.deleteServiceAlert(assetAlert.id)}
                        title="Are you sure to delete this alert？"
                        icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}>
                        <a href="#" className="btn btn-empty ml-2">
                            <Icon name="close" />
                        </a>
                    </Popconfirm>
                </div>
            );
            // tempEvent.asset_reg = event.registration;
            return tempAssetAlert;
        });
        return (
            <Spin spinning={isFetching && isFetching.length > 0}>
                <Row>
                    <Col span={24}>
                        <Button style={{ float: 'right' }} type="primary" className="btn" onClick={this.createForm}>
                            add service alert
                        </Button>
                    </Col>
                </Row>
                <GridView
                    // filterTemplate='<div><div style="display: inline-block; width: 45%">{{label}}</div><div style="display: inline-block; width: 45%">{{element}}</div><div></div></div>'
                    data={listAssetServiceAlerts}
                    onChange={this.onTableChange}
                    sortableColumns={['start_time', 'end_time', 'time_driven', 'driver', 'area_driven', 'journey_score', 'journey_events']}
                    pagination={{
                        total: parseInt(serviceAlertsPagination.totalRecords, 10),
                        showSizeChanger: false,
                        pageSize: parseInt(serviceAlertsPagination.perPageCount, 10),
                        onChange: this.onPageChange,
                    }} />
                {showCreateAlertForm
                    ? <CreateServiceAlertForm serviceAlert={serviceAlert} clearForm={this.clearForm} onSubmit={this.createServiceAlert} />
                    : <div style={{ height: 430 }} />}
            </Spin>
        );
    }
}

AssetServiceAlertsTab.defaultProps = {
    actions: {},
    setLoadedState: () => null,
    serviceAlertsTabLoaded: false,
    assetId: 0,
    onPageChange: () => null,
    onTableChange: () => null,
    serviceAlerts: [],
    serviceAlertsPagination: {},
};

AssetServiceAlertsTab.propTypes = {
    actions: PropTypes.object,
    setLoadedState: PropTypes.func,
    serviceAlertsTabLoaded: PropTypes.bool,
    assetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    serviceAlerts: PropTypes.array,
    serviceAlertsPagination: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        serviceAlerts: state.asset.serviceAlerts,
        serviceAlertsPagination: state.asset.serviceAlertsPagination,
        isFetching: state.asset.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetServiceAlertsTab);
