import React from 'react';
import { Layout as AntLayout, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';

import './noAccess.scss';
import Menu from '../../components/layout/Menu';
import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';

export default function () {
    return (
        <Layout
            data-test="pages-notfound"
            showHeader={false}
            className="no-access"
            title={<div>No Accesss</div>}
            headerTitle={<div>No Accesss</div>}
            showFooter={false}>
            <div className="header">
                <AntLayout.Header className="header__inner">
                    <Row type="flex" justify="space-between">
                        <Col>
                            <div className="header__logos">
                                <img src={srcFleetclearLogo} className="header__logo-fleet" alt="Fleetclear" />
                            </div>
                        </Col>
                    </Row>
                </AntLayout.Header>
            </div>
            <div className="content-wrap">
                <FilterSidebar />
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <p className="heading">403</p>
                                <p className="small-heading">Insufficient Permission.</p>
                                <p className="normal">It seems you do not have the correct permission to access this feature. Please reach out to support, if you think this might be wrong</p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
