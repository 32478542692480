/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './bestDriverChart';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';

class BestDriverChart extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            timeframe: props.timeframe,
        };
    }

    onTimeframeChange(timeframe) {
        const { onTimeframeChange } = this.props;

        this.setState({
            timeframe,
        });

        onTimeframeChange(timeframe);
    }

    componentDidMount() {
        const { actions } = this.props;
        // actions.getBestDriverChartDataRequest();
    }

    render() {
        return this.view();
    }
}

BestDriverChart.defaultProps = {
    onTimeframeChange: () => null,
    rightMenuOnClick: () => null,
    rightMenuItems: [],
    tooltipText: null,
    onFirstOptionChange: () => null,
    onSecondOptionChange: () => null,
    onThirdOptionChange: () => null,
    onFourOptionChange: () => null,
    defaultFirstOptionValue: 'All',
    defaultSecondOptionValue: 'All',
    defaultThirdOptionValue: 'All',
    defaultFourOptionValue: 'All',
    userDivisions: [],
    drivers: [],
    assets: [],
    heightRatio: 1,
    imageUrl: '',
    leftSubtitle: 'AVERAGE DRIVER SCORE',
    leftData: '',
    leftDataClass: 'red',
    rightData: ' out of all Fleetclear operators',
    rightSubtitle: 'NATIONWIDE COMPARISON',
    rightDataClass: 'red',
    bottomTitle: 'Best driver: ',
    bottomData: '',
    bottomDataClass: 'red',
};

BestDriverChart.propTypes = {
    timeframe: PropTypes.oneOf(['hourly', 'daily', 'weekly', 'monthly', 'yearly']).isRequired,
    rightMenuItems: PropTypes.array,
    onTimeframeChange: PropTypes.func,
    rightMenuOnClick: PropTypes.func,
    tooltipText: PropTypes.string,
    onFirstOptionChange: PropTypes.func,
    onSecondOptionChange: PropTypes.func,
    onThirdOptionChange: PropTypes.func,
    onFourOptionChange: PropTypes.func,
    defaultFirstOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultSecondOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultThirdOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultFourOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    userDivisions: PropTypes.array,
    drivers: PropTypes.array,
    assets: PropTypes.array,
    heightRatio: PropTypes.oneOf([1, 2]),
    leftSubtitle: PropTypes.string,
    leftData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rightSubtitle: PropTypes.string,
    rightData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    leftDataClass: PropTypes.oneOf(['success', 'error', 'warning']),
    rightDataClass: PropTypes.oneOf(['success', 'error', 'warning']),
    bottomTitle: PropTypes.string,
    bottomData: PropTypes.string,
    bottomDataClass: PropTypes.oneOf(['success', 'error', 'warning']),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        bestDriverChartData: state.smartWidget.bestDriverChartData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...smartWidgetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BestDriverChart);
