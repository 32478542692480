import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input, Select, Switch } from 'antd';

const { Option } = Select;

class LeftSide extends Component {
    constructor(props) {
        super(props);
        const { profile } = props;

        this.state = {
            name: profile.name,
            email: profile.email,
            role: profile.role,
            department: profile.department,
            division: profile.division,
            status: profile.status,
            password: null,
            two_factor_auth: profile.two_factor_auth,
        };
    }

    componentDidUpdate(prevProps) {
        const { profile } = this.props;
        const { profile: oldProfile } = prevProps;

        if (
            oldProfile.name !== profile.name || 
            oldProfile.email !== profile.email || 
            oldProfile.role !== profile.role || 
            oldProfile.department !== profile.department || 
            oldProfile.division !== profile.division || 
            oldProfile.status !== profile.status ||
            oldProfile.two_factor_auth !== profile.two_factor_auth
        ) {
            this.setState({
                name: profile.name,
                email: profile.email,
                role: profile.role,
                department: profile.department,
                division: profile.division,
                status: profile.status,
                password: null,
                two_factor_auth: profile.two_factor_auth,
            });
        }
    }

    componentDidMount() {
        const { registerGetDetailsHandler } = this.props;

        registerGetDetailsHandler(this.getDetails);
    }

    getDetails = () => {
        const {
            name,
            email,
            role,
            department,
            division,
            status,
            password,
            two_factor_auth,
        } = this.state;

        return {
            name,
            email,
            role,
            department,
            division,
            status,
            password,
            two_factor_auth,
        };
    }

    render() {
        const {
            name,
            email,
            role,
            status,
            password,
            two_factor_auth,
        } = this.state;

        const { currentUserRole } = this.props;

        let disableRoleSelection = false;
        if (currentUserRole === 'manager' && role !== 'user') {
            disableRoleSelection = true;
        }

        if (currentUserRole === 'admin' && role !== 'user' && role !== 'admin') {
            disableRoleSelection = true;
        }

        let roleSelect = (
            <Select value={role} onChange={(v) => this.setState({ role: v })} style={{ width: '100%' }} disabled={disableRoleSelection}>
                {currentUserRole === 'super-admin' || currentUserRole === 'admin' ? <Option value="manager">Manager</Option> : null}
                <Option disabled value="user">User</Option>
                {currentUserRole === 'super-admin' ? <Option value="admin">Admin</Option> : null}
                {currentUserRole === 'super-admin' ? <Option value="super-admin">Super Administrator</Option> : null}
            </Select>
        );
        if (role == 'admin' || role == 'super-admin') {
            roleSelect = <Input value={role} disabled />;
        }

        const showEditPassword = 
            (currentUserRole === 'admin' || currentUserRole === 'super-admin') && 
            (role === 'user' || role === 'driver' || role === 'manager');
        const showEditTwoFactorAuth = showEditPassword && two_factor_auth !== undefined;

        return (
            <ul className="list left-side-user-list">
                <li className="list__item">
                    <div className="list__item-name">
                        Name:
                    </div>
                    <div className="list__item-value">
                        <Input value={name} onChange={(v) => this.setState({ name: v.target.value })} />
                    </div>
                </li>
                <li className="list__item">
                    <div className="list__item-name">
                        Email:
                    </div>
                    <div className="list__item-value">
                        <Input value={email} onChange={(v) => this.setState({ email: v.target.value })} />
                    </div>
                </li>
                <li className="list__item">
                    <div className="list__item-name">
                        Role:
                    </div>
                    <div className="list__item-value">
                        {roleSelect}
                    </div>
                </li>
                <li className="list__item">
                    <div className="list__item-name">
                        Status:
                    </div>
                    <div className="list__item-value">
                        <Select 
                            value={`${status}`} 
                            onChange={(v) => this.setState({ status: parseInt(v, 10) })} 
                            style={{ width: '100%' }}
                        >
                            <Option value="1">Active</Option>
                            <Option value="0">Inactive</Option>
                        </Select>
                    </div>
                </li>
                {showEditPassword ? (
                    <li className="list__item">
                    <div className="list__item-name">
                        New Password:
                    </div>
                    <div className="list__item-value">
                        <Input 
                            value={password} 
                            onChange={(v) => this.setState({ password: v.target.value })}
                            type='password' 
                        />
                    </div>
                </li>
                ) : null}
                {showEditTwoFactorAuth ? (
                    <li className="list__item">
                        <div className="list__item-name">
                            <Checkbox
                                defaultChecked={two_factor_auth > 0}
                                onChange={(value) => { this.setState({ two_factor_auth: value ? 1 : 0 }); }} 
                            />
                            &ensp;
                            Enable Two-factor Authentication?
                        </div>
                    </li>
                ) : null}
            </ul>
        );
    }
}

LeftSide.defaultProps = {
    currentUserRole: '',
    departments: [],
    profile: {
        name: '',
        email: '',
        role: '',
        department: '',
        division: 1,
        status: 1,
    },
    registerGetDetailsHandler: () => null,
};

LeftSide.propTypes = {
    currentUserRole: PropTypes.string.isRequired,
    departments: PropTypes.array.isRequired,
    registerGetDetailsHandler: PropTypes.func,
    profile: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
        role: PropTypes.string,
        department: PropTypes.string,
        division: PropTypes.number,
        status: PropTypes.number,
    }),
};

export default LeftSide;
