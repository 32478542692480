/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Empty } from 'antd';
import { isEmpty } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    render() {
        const {
            speedReport,
            isFetching,
            toggleFilterSidebar,
        } = this.props;

        const listReports = speedReport.map((report) => (
            {
                ...report,
            }
        ));

        return (
            <Spin spinning={isFetching}>
                <div className="report-table-with-totals">
                    {isEmpty(listReports) ? <CustomEmpty type={CustomEmptyType.Report} />
                        : (
                            <GridView
                                data={listReports}
                                onChange={this.onTableChange}
                                disablePagination />
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    speedReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    speedReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        speedReport: state.report.speedReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
