/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../../../components/layout/Layout';
import FilterSidebar from '../../../../components/FilterSidebar';
import Icon from '../../../../components/elements/Icon';
import ReportFilter, { DATE_MODE_FREQUENCY } from '../../ReportFilter';
import ReportTab from './tabs/ReportTab';

export default function () {
    const {
        eventsTabLoaded, eventsTabFilter, filterSidebar, filters,
    } = this.state;
    const { user } = this.props;

    return (
        <Layout
            className="events"
            title={<div>Driving Time / Vehicle Usage Time Report</div>}
            headerTitle={<div>Driving Time / Vehicle Usage Time Report Page</div>}
        >
            <div className={`content-wrap${user?.userCompany?.currentAlert ? '-with-banner' : ''}`}>
                <FilterSidebar displayFilter={filterSidebar}>
                    <ReportFilter 
                        onSubmit={this.onEventsTabFilterChange} 
                        filters={filters}
                        showDivision
                        dateMode={DATE_MODE_FREQUENCY}
                        showDrivingTimeReportTimeType
                    />
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <div className="section__action-bar">
                                    {/* <Button
                                        onClick={() => this.exportCsv()}
                                        className="btn ant-btn-primary ant-btn-xs middlewide">
                                        Export
                                    </Button> */}
                                </div>
                                <ReportTab
                                    toggleFilterSidebar ={() => this.setState({filterSidebar: !filterSidebar})}
                                    eventsTabFilter={eventsTabFilter}
                                    setLoadedState={this.eventsTabLoaded}
                                    eventsTabLoaded={eventsTabLoaded}
                                    onTableChange={this.onEventsTabSortChange}
                                    onPageChange={this.onEventsTabPageChange} />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
