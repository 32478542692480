import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    Input,
    Spin,
    Checkbox,
} from 'antd';
import Icon from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as eventsActions from '../../../core/events/eventsActions';

class AlertsTab extends Component {
    componentDidMount() {
        const { actions } = this.props;
        actions.getCompanyEventsRequest();
    }

    change = (data) => {
        const { onChange } = this.props;
        onChange({
            id: data.key,
            name: data.name,
            email: data.email,
            notify: data.notify,
            icon: data.icon,
        });
    };

    switchChange = (val, obj) => {
        const myObj = obj;
        myObj.notify = val;
        this.change(myObj);
    };

    inputChange = (val, obj) => {
        const myObj = obj;
        myObj.email = val.target.value;
        this.change(myObj);
    };

    render() {
        const { isFetching, value } = this.props;
        const columnsAlert = [
            {
                title: 'enabled',
                key: 'date',
                align: 'center',
                width: 100,
                render: (val) => <Checkbox onChange={(n) => this.switchChange(n, val)} defaultChecked={val.notify} size="small" />,
            },
            {
                title: 'icon',
                key: 'icon',
                align: 'center',
                width: 100,
                render: (i) => {
                    if (i.icon !== '' && i.icon !== null) {
                        return (
                            <LegacyIcon 
                                type={i.icon} 
                                style={{ 
                                    color: 'red',
                                    fontSize: '16px',
                                }}
                            />
                        );
                    }
                    return null;
                },
            },
            {
                title: 'event',
                key: 'telematicsEvent',
                dataIndex: 'name',
                align: 'left',
                width: 264,
            },
            {
                title: 'Target Email Addresses',
                key: 'email',
                align: 'center',
                render: (val) => <Input onBlur={(n) => this.inputChange(n, val)} defaultValue={val.email} size="small" />,
            },
        ];
        const dataAlert = value.map((v) => ({
            key: v.id,
            name: v.name,
            email: v.email,
            notify: v.notify !== '0',
            icon: v.icon,
        }));

        return (
            <Spin spinning={isFetching}>
                <div
                    style={{
                        width: '100%',
                        minWidth: '700px',
                    }}>
                    <Table
                        columns={columnsAlert}
                        dataSource={dataAlert}
                        pagination={false} 
                        scroll={{
                            x: false,
                            y: 'calc(66vh)',
                        }}
                    />
                </div>
            </Spin>
        );
    }
}

AlertsTab.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            name: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
            notify: PropTypes.oneOf(['0', '1']).isRequired,
            icon: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        events: state.events.events,
        eventsPagination: state.events.eventsPagination,
        isFetching: state.events.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertsTab);
