/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Spin, Row, Col, Card, Tabs,
} from 'antd';
import moment from 'moment';
import {
    Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip as ToolTipChart,
} from 'recharts';
import Map from '../../components/Map';
import { isNull } from 'underscore';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import SingleJourneyTab from './SingleJourneyTab';
import DefaultDrivePicturePNG from '../../assets/images/default-image.png';
import PlaceholderAssetImage from '../../assets/images/placeholder-asset-image.png';
import ProgressCircle from '../../components/elements/ProgressCircle';
import journeyScorePNG from '../../assets/images/journey-score.png';
import journeyTimePNG from '../../assets/images/clock.png';
import journeySpeedPNG from '../../assets/images/speed.png';
import averageMpgPNG from '../../assets/images/average-mpg.png';
import cyclearLogoPNG from '../../assets/images/cyclear-logo.png';
import idClearLogoJPG from '../../assets/images/id-clear-no-trademark.jpg';
import reclearLogoPNG from '../../assets/images/reclear-logo.png';
import { secondsToHms } from '../../core/utils/functions';

import './journeysView.scss';

const { GOOGLE_MAP_API_KEY } = require('../../core/constants').default;
const { TabPane } = Tabs;

export default function () {
    const {
        selectedJourneys, isFetching, journeyIds, mapSelectedDeviceLocations, uomPreference, user,
    } = this.props;
    const { centerMap, currentTabKey } = this.state;
    
    const trimmedPath = mapSelectedDeviceLocations && mapSelectedDeviceLocations.length ? mapSelectedDeviceLocations.map((location, i, arr) => {
        const tempLocation = {};
        tempLocation.lat = location.lat;
        tempLocation.lng = location.lng;
        if (i === 0) tempLocation.position = "end";
        else if (i == (arr.length - 1)) tempLocation.position = "start";
        else tempLocation.position = "path";
        return tempLocation;
    }) : [];

    // Calculate combined stats (averaged/totalled/earliest/latest)
    let earliestJourney = null;
    let latestJourney = null;
    let startArea = '';
    let endArea = '';
    let journeyStart = '';
    let journeyEnd = '';
    let score = 0;
    let driverScore = 0;
    let events = 0;
    let timeDriven = 0;
    let averageSpeed = 0;
    let idleTime = 0;
    let trueIdleTime = 0;
    let averageMpg = 0;
    let mpgSuffix = uomPreference === 'british-imperial' ? 'UK mpg' : 'L/100km';
    if (selectedJourneys && selectedJourneys[0]) {
        earliestJourney = selectedJourneys[0];
        latestJourney = selectedJourneys[0];
        let scoreTotal = 0;
        let driverScoreTotal = 0;
        selectedJourneys.forEach(sj => {
            if (sj.startInterval < earliestJourney.startInterval) earliestJourney = sj;
            if (sj.endInterval > latestJourney.endInterval) latestJourney = sj;
            scoreTotal += parseInt(sj.info.score);
            driverScoreTotal += parseInt(sj.driverAverageScore);
            events += parseInt(sj.info.events);
            timeDriven += parseInt(sj.info.time_driven);
            idleTime += parseInt(sj.info.idle_time);
            trueIdleTime += parseInt(sj.info.true_idle_time);
            averageMpg += parseInt(sj.info.mpg.slice(0, sj.info.mpg.indexOf(mpgSuffix)));
        });
        startArea = earliestJourney.info.start_area;
        endArea = latestJourney.info.end_area;
        journeyStart = earliestJourney.info.start;
        journeyEnd = latestJourney.info.end;
        score = parseInt(scoreTotal / selectedJourneys.length);
        driverScore = parseInt(driverScoreTotal / selectedJourneys.length);
        timeDriven = secondsToHms(timeDriven);
        averageMpg = parseInt(averageMpg / selectedJourneys.length);
        averageMpg = `${averageMpg} ${mpgSuffix}`;
    }
    const chartData = [
        {
            name: 'Idle Time', 
            pv: idleTime || 0,
        },
        {
            name: 'True Idle', 
            pv: trueIdleTime || 0,
        },
    ];

    let profile_picture = '';
    if (selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info) {
        profile_picture = (
            <img
                className="driver-info-image"
                alt={selectedJourneys[0].info.driver || '[not-set]'}
                src={selectedJourneys[0].info.profile_picture || DefaultDrivePicturePNG} 
                style={{ objectFit: 'cover' }}
                height={120}
                width={120}
            />
        );
    }
    let asset_picture = '';
    if (selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info) {
        asset_picture = (
            <img
                className="driver-info-image"
                alt={selectedJourneys[0].info.registration || '[not-set]'}
                src={selectedJourneys[0].info.asset_picture || PlaceholderAssetImage} 
                style={{ objectFit: 'cover' }}
                height={120}
                width={120}
            />
        );
    }
    
    return (
        <Layout
            data-test="pages-journey-view"
            className="events-view"
            title={<div>Journeys View</div>}
            headerTitle={<div>Journeys View</div>}
            showFooter={false}
        >
            <div className={`content-wrap${user?.userCompany?.currentAlert ? '-with-banner' : ''}`}>
                <FilterSidebar />
                <div className="content">
                    <Spin spinning={isFetching || isNull(selectedJourneys) || !trimmedPath.length}>
                        <div className="content-inner">
                            <section className="section">
                                <Tabs
                                    onTabClick={this.handleTabChange}
                                    defaultActiveKey={currentTabKey}
                                    activeKey={currentTabKey}
                                    animated={false}
                                    className="tabs-restyled"
                                >
                                    <TabPane 
                                        tab="Journeys Summary" 
                                        key="1"
                                    >
                                        <div 
                                            className="section__body" 
                                            style={{ height: '1050px' }}
                                        >
                                            <Row
                                                gutter={40}
                                                type="flex"
                                                justify="space-between"
                                                className="data-row map-row"
                                            >
                                                <Col 
                                                    xl={24} 
                                                    style={{ 
                                                        height: 350, 
                                                        width: '100%' 
                                                    }}
                                                >
                                                    <br />
                                                    {journeyIds && journeyIds.length ? (
                                                        <>
                                                            <p className='report-title__centered-text'>
                                                                <strong>Summary of Journeys:</strong>&ensp;{journeyIds.join(', ')}
                                                            </p>
                                                            <p className='report-title__centered-text'>
                                                                <strong>Date:</strong>&ensp;{selectedJourneys && selectedJourneys.length 
                                                                    ? moment(selectedJourneys[0].info.start).format('YYYY-MM-DD') 
                                                                    : 'Loading...'
                                                                }
                                                            </p>
                                                        </>
                                                    ) : null}
                                                    <br />
                                                    <Map
                                                        ref={this.googleMapAccess}
                                                        mapDate={moment().subtract('1', 'days').format('DD/MM/YYYY')}
                                                        apiKey={GOOGLE_MAP_API_KEY}
                                                        showToolbar={false}
                                                        sliderStep={1}
                                                        sliderValue={0}
                                                        sliderMinValue={0}
                                                        sliderMaxValue={0}
                                                        sliderValueUnit="seconds"
                                                        hideDatePicker
                                                        center={centerMap}
                                                        onSliderChange={() => {}}
                                                        allowStreetView
                                                        showFindCenterButton={false}
                                                        path={trimmedPath}
                                                    />
                                                    <br />
                                                    <br />
                                                    <Row 
                                                        gutter={[24, 24]} 
                                                        className="data-row event-view-driver-info" 
                                                        ref={this.pdfDocumentRef}
                                                    >
                                                        <Col 
                                                            xs={24} 
                                                            sm={24} 
                                                            md={24} 
                                                            lg={5} 
                                                            xl={6} 
                                                            style={{ verticalAlign: 'top' }}
                                                        >
                                                            <div className="data-row-header">DRIVER INFORMATION</div>
                                                            <div className="data-block">
                                                                <Row 
                                                                    gutter={[24, 24]} 
                                                                    style={{ minHeight: 120 }}
                                                                >
                                                                    <Col 
                                                                        xs={12} 
                                                                        sm={4} 
                                                                        md={12} 
                                                                        lg={10}
                                                                    >
                                                                        {profile_picture}
                                                                    </Col>
                                                                    <Col 
                                                                        xs={12} 
                                                                        sm={4} 
                                                                        md={12} 
                                                                        lg={14}
                                                                    >
                                                                        <div className="title">
                                                                            <p>
                                                                                {selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info && selectedJourneys[0].info.user_id
                                                                                    ? (
                                                                                        <Link
                                                                                            to={`/drivers/view/${(selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info && selectedJourneys[0].info.user_id) || null}`}
                                                                                        >
                                                                                            {(selectedJourneys[0].info && selectedJourneys[0].info.driver) || '[not-set]'}
                                                                                        </Link>
                                                                                    ) : (
                                                                                        selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info && selectedJourneys[0].info.driver
                                                                                    ) || '[not-set]'
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                        <p>
                                                                            <span className="driver">Driver</span>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div className="data-block">
                                                                <Row 
                                                                    gutter={[24, 24]} 
                                                                    style={{ minHeight: 120 }}
                                                                >
                                                                    <Col 
                                                                        xs={12} 
                                                                        sm={4} 
                                                                        md={12} 
                                                                        lg={10}
                                                                    >
                                                                        {asset_picture}
                                                                    </Col>
                                                                    <Col 
                                                                        xs={12} 
                                                                        sm={4} 
                                                                        md={12} 
                                                                        lg={14}
                                                                    >
                                                                        <div className="title">
                                                                            {selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info && selectedJourneys[0].info.asset_id
                                                                                ? (
                                                                                    <Link
                                                                                        to={`/assets/view/${(selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info && selectedJourneys[0].info.asset_id) || null}`}
                                                                                    >
                                                                                        {(selectedJourneys[0].info && selectedJourneys[0].info.registration) || '[not-set]'}
                                                                                    </Link>
                                                                                ) : (selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info && selectedJourneys[0].info.registration) || '[not-set]'}
                                                                        </div>
                                                                        <Row 
                                                                            gutter={[24, 24]} 
                                                                        >
                                                                            <Col 
                                                                                xs={12} 
                                                                                sm={4} 
                                                                                md={24} 
                                                                                lg={12}
                                                                            >
                                                                                <div className="asset-info-heading">Last service:</div>
                                                                            </Col>
                                                                            <Col 
                                                                                xs={12} 
                                                                                sm={4} 
                                                                                md={12} 
                                                                                lg={12}
                                                                            >
                                                                                <div className="asset-info-heading">Registration:</div>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row gutter={[24, 24]}>
                                                                            <Col 
                                                                                xs={12} 
                                                                                sm={4} 
                                                                                md={24} 
                                                                                lg={12}
                                                                            >
                                                                                <div
                                                                                    className="asset-info">{(selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info && selectedJourneys[0].info.last_service) || 'Unknown'}
                                                                                </div>
                                                                            </Col>

                                                                            <Col xs={12} sm={4} md={24} lg={12}>
                                                                                <div
                                                                                    className="asset-info">{(selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info && selectedJourneys[0].info.registration) || '[not-set]'}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div className="data-block">
                                                                <div className="data-row-header">DAY INFORMATION</div>
                                                                <Row className="journey-info-row">
                                                                    <Col xs={12}>
                                                                        <strong>Day Start</strong>
                                                                    </Col>
                                                                    <Col xs={12}>{journeyStart}</Col>
                                                                </Row>
                                                                <Row className="journey-info-row">
                                                                    <Col xs={12}>
                                                                        <strong>Day Start Area</strong>
                                                                    </Col>
                                                                    <Col 
                                                                        xs={12} 
                                                                        style={{ wordBreak: 'break-all' }}
                                                                    >
                                                                        {startArea}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="journey-info-row">
                                                                    <Col xs={12}>
                                                                        <strong>Day End</strong>
                                                                    </Col>
                                                                    <Col xs={12}>{journeyEnd}</Col>
                                                                </Row>
                                                                <Row className="journey-info-row">
                                                                    <Col xs={12}>
                                                                        <strong>Day End Area</strong>
                                                                    </Col>
                                                                    <Col 
                                                                        xs={12} 
                                                                        style={{ wordBreak: 'break-all' }}
                                                                    >
                                                                        {endArea}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="journey-info-row">
                                                                    <Col xs={12}><strong>Avg. Journey Score</strong></Col>
                                                                    <Col xs={12}>{score}</Col>
                                                                </Row>
                                                                <Row className="journey-info-row">
                                                                    <Col xs={12}><strong>Day Events</strong></Col>
                                                                    <Col xs={12}>{events}</Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col 
                                                            xs={24} 
                                                            sm={24} 
                                                            md={24} 
                                                            lg={5} 
                                                            xl={18} 
                                                            style={{ verticalAlign: 'top' }} 
                                                        />
                                                        <Col 
                                                            xs={24} 
                                                            sm={24} 
                                                            md={24} 
                                                            lg={24} 
                                                            xl={18}
                                                        >
                                                            <Row>
                                                                <Col 
                                                                    xs={24} 
                                                                    sm={24} 
                                                                    md={6} 
                                                                    lg={5} 
                                                                    className="telematics-data-card-col first"
                                                                >
                                                                    <div className="telematics-data-card-title">
                                                                        <span className="telematics-data-card-title-text">
                                                                            Activations
                                                                        </span>
                                                                    </div>
                                                                    <Card 
                                                                        className="telematics-data-card" 
                                                                        style={{ height: 100 }}
                                                                    >
                                                                        <Row gutter={[24, 24]}>
                                                                            <Col 
                                                                                xs={8} 
                                                                                sm={4} 
                                                                                md={6} 
                                                                                lg={6}
                                                                            >
                                                                                <div className="activations-icon">
                                                                                    <img 
                                                                                        src={cyclearLogoPNG} 
                                                                                        alt="Cyclear activations" 
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col 
                                                                                xs={8} 
                                                                                sm={4} 
                                                                                md={6} 
                                                                                lg={14}
                                                                            >
                                                                                <div className="activations-title">
                                                                                    Cyclear Activations
                                                                                </div>
                                                                            </Col>
                                                                            <Col 
                                                                                xs={8} 
                                                                                sm={4} 
                                                                                md={6} 
                                                                                lg={2}
                                                                            >
                                                                                <div className="activations-stat">
                                                                                    0
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </Col>
                                                                <Col 
                                                                    xs={24} 
                                                                    sm={24} 
                                                                    md={6} 
                                                                    lg={5} 
                                                                    className="telematics-data-card-col middle"
                                                                >
                                                                    <Card style={{ height: 121 }}>
                                                                        <div className="telematic-box-stat-icon">
                                                                            <img 
                                                                                src={averageMpgPNG} 
                                                                                alt="average mpg" 
                                                                            />
                                                                        </div>
                                                                        <div className="telematic-box-stat-text">
                                                                            AVERAGE MPG
                                                                        </div>
                                                                        <div className="telematic-box-stat">
                                                                            {averageMpg}
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                                <Col 
                                                                    xs={24} 
                                                                    sm={24} 
                                                                    md={6} 
                                                                    lg={5} 
                                                                    className="telematics-data-card-col first"
                                                                >
                                                                    <div className="telematics-data-card-title">
                                                                        <span className="telematics-data-card-title-text">
                                                                            Activations
                                                                        </span>
                                                                    </div>
                                                                    <Card 
                                                                        className="telematics-data-card" 
                                                                        style={{ height: 100 }}
                                                                    >
                                                                        <Row gutter={[24, 24]}>
                                                                            <Col 
                                                                                xs={8} 
                                                                                sm={4} 
                                                                                md={6} 
                                                                                lg={8}
                                                                            >
                                                                                <div className="activations-icon">
                                                                                    <img 
                                                                                        src={idClearLogoJPG} 
                                                                                        alt="IDClear activations" 
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col 
                                                                                xs={8} 
                                                                                sm={4} 
                                                                                md={6} 
                                                                                lg={12}
                                                                            >
                                                                                <div className="activations-title">
                                                                                    IDClear Activations
                                                                                </div>
                                                                            </Col>
                                                                            <Col 
                                                                                xs={8} 
                                                                                sm={4} 
                                                                                md={6} 
                                                                                lg={2}
                                                                            >
                                                                                <div className="activations-stat">
                                                                                    0
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </Col>
                                                                <Col 
                                                                    xs={24} 
                                                                    sm={24} 
                                                                    md={6} 
                                                                    lg={5} 
                                                                    className="telematics-data-card-col"
                                                                >
                                                                    <div className="telematics-data-card-title">
                                                                        <span className="telematics-data-card-title-text">
                                                                            Activations
                                                                        </span>
                                                                    </div>
                                                                    <Card 
                                                                        className="telematics-data-card"
                                                                        style={{ height: 100 }}
                                                                    >
                                                                        <Row gutter={[24, 24]}>
                                                                            <Col 
                                                                                xs={8} 
                                                                                sm={4} 
                                                                                md={6} 
                                                                                lg={8}
                                                                            >
                                                                                <div className="activations-icon">
                                                                                    <img 
                                                                                        src={reclearLogoPNG} 
                                                                                        alt="Reclear activations" 
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col 
                                                                                xs={8} 
                                                                                sm={4} 
                                                                                md={6} 
                                                                                lg={12}
                                                                            >
                                                                                <div className="activations-title">
                                                                                    Reaclear Activations
                                                                                </div>
                                                                            </Col>
                                                                            <Col 
                                                                                xs={8} 
                                                                                sm={4} 
                                                                                md={6} 
                                                                                lg={2}
                                                                            >
                                                                                <div className="activations-stat">
                                                                                    0
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </TabPane>
                                    {selectedJourneys && selectedJourneys.length && selectedJourneys.map((sj, i) => {
                                        return (
                                            <TabPane 
                                                tab={`${sj.info.start.slice(sj.info.start.indexOf(' ') + 1)} - ${sj.info.end.slice(sj.info.end.indexOf(' ') + 1)}` }
                                                key={`${i + 2}`}
                                            >
                                                <SingleJourneyTab 
                                                    journeyId={sj.info.id} 
                                                    currentTabKey={currentTabKey} 
                                                    tabKey={`${i + 2}`}
                                                />
                                            </TabPane>
                                        );
                                    })}
                                </Tabs>
                            </section>
                        </div>
                    </Spin>
                </div>
            </div>
        </Layout>
    );
}
