import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './videoCachedView';
import * as eventsActions from '../../core/events/eventsActions';
import * as globalActions from '../../core/global/globalActions';
import * as reportActions from '../../core/report/reportActions';

class VideoCachedView extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.googleMapAccess = React.createRef();
    }

    componentDidMount() {
        /**
         * @todo make the route to use Auth
         */
        const { actions, match, shardId } = this.props;
        const { videoReference } = match.params;
        const params = {};
        params.videoReference = videoReference;
        params.shardId = shardId || '1';
        actions.getEventInfoByVideoReferenceRequest(params);
    }

    render() {
        return this.view();
    }
}

VideoCachedView.propTypes = {

};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        selectedEvent: state.events.selectedEvent,
        isFetching: state.events.isFetching,
        speedingReportBack: state.report.speedingReportBack,
        referrer: state.referrer,
        updateMapEventData: state.events.updateMapEventData,
        shardId: state.global.shardId,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
                ...globalActions,
                ...reportActions,
            },
            dispatch,
        ),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoCachedView);
