import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Empty, Spin } from 'antd';

import { isEmpty } from 'underscore';

import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetActions from '../../../../../core/asset/assetActions';
import * as driverActions from '../../../../../core/driver/driverActions';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    render() {
        const {
            outsideWorkingHoursReport,
            isFetching,
        } = this.props;
        let listReports = [];

        if (outsideWorkingHoursReport && outsideWorkingHoursReport.length > 0) {
            listReports = outsideWorkingHoursReport.map((report) => ({
                asset: report.asset,
                registration: report.registration,
                division: report.division,
                driver: report.driver,
                start_date: report.start.split(' ')[0],
                start_time: report.start.split(' ')[1],
                end_date: report.end.split(' ')[0],
                end_time: report.end.split(' ')[1],
                journey_duration: report.journey_duration,
                moving: report.moving,
                idle: report.idle,
                start_area: report.start_area,
                end_area: report.end_area,
            }));
        }
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports) ? <CustomEmpty type={CustomEmptyType.Report} />
                        : (
                            <>
                                <br />
                                <GridView
                                    exportableColumns={exportableColumns}
                                    data={listReports}
                                    onChange={this.onTableChange}
                                    disablePagination
                                    showExportButton 
                                />
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    outsideWorkingHoursReport: [],
    filters: {},
    actions: {},
};

ReportTab.propTypes = {
    outsideWorkingHoursReport: PropTypes.array,
    filters: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    actions: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        outsideWorkingHoursReport: state.report.outsideWorkingHoursReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
