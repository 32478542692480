import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import DOM from './outsideWorkingHoursReportPage';
import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as globalActions from '../../../../core/global/globalActions';

class OutsideWorkingHoursReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Set the from and to dates to the current date
            filters: {
                date_from: moment(),
                date_to: moment(),
            },
            filterSidebar: true,
        };

        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.resetReportsRequest();

        this.onEventsTabFilterChange({
            asset_or_driver: 'assets',
            page: 1,
            date_from: moment().subtract(1, 'day'),
            date_to: moment(),
            inside_outside: 'inside',
            start_hour: moment().hour(9), // set the hour to 9
            end_hour: moment().hour(17), // set the hour to 17
        });
    }

    onEventsTabFilterChange = (values) => {
        const { actions } = this.props;
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format('YYYY-MM-DD');
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }
        if (filters.asset_id) {
            filters.asset_info = values.asset_id;
        }
        filters.page = 1;
        const params = { ...filters };
        if (filters.start_hour) {
            const startHourString = values.start_hour instanceof moment 
                ? values.start_hour.startOf('hour').format('HH:mm') 
                : values.start_hour;
            params.start_hour = startHourString;
            filters.start_hour = startHourString;
        }
        if (filters.end_hour) {
            const endHourString = values.end_hour instanceof moment 
                ? values.end_hour.startOf('hour').format('HH:mm')
                : values.end_hour;
            params.end_hour = endHourString;
            filters.end_hour = endHourString;
        }
        this.setState({ filters });
        actions.getOutsideWorkingHoursReportRequest(params);
    };

    render() {
        return this.view();
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        filterSidebar: state.filterSidebar,
        outsideWorkingHoursReport: state.report.outsideWorkingHoursReport,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OutsideWorkingHoursReport);
