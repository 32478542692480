import React from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Button, Spin } from 'antd';

const FormItem = Form.Item;

export default function () {
    const { user, form } = this.props;
    const { getFieldDecorator } = form;

    return (
        <Spin spinning={user.isFetching}>
            <Form onSubmit={this.handleSubmit} layout="vertical" className="form mt-3">
                <FormItem label="Email">
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                message: 'Please enter a valid Email',
                            },
                            {
                                required: true,
                                message: 'Please input Email!',
                            },
                        ],
                    })(<Input type="text" size="large" />)}
                </FormItem>
                <div className="text-center mt-5">
                    <Button type="primary" htmlType="submit">
                        Create User
                    </Button>
                </div>
            </Form>
        </Spin>
    );
}
