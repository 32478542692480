/* global window */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    Spin, DatePicker, Button, Row, Col,
} from 'antd';
import { Responsive, WidthProvider } from 'react-grid-layout';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import history from '../../core/utils/history';
import Layout from '../../components/layout/Layout';
import SmartWidgetItem from './SmartWidgetItem';

import './dashboard.scss';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function () {
    const { smartWidget, actions, telematicsRetentionDays, user } = this.props;
    const { date } = this.state;

    const realWindowWidth = window.innerWidth - 40 > 1200 ? 1200 : window.innerWidth - 40;
    const x = (realWindowWidth) / 4;

    return (
        <Layout
            data-test="pages-dashboard"
            className="dashboard"
            title={<div>Dashboard</div>}
            headerTitle={<div>Reports Page</div>}
            showFooter={false}
        >
            <div className={`content-wrap${user?.userCompany?.currentAlert ? '-with-banner' : ''}`}>
                <div className="filter" />
                <div className="dashboard-container" style={{ padding: '0 15px', marginTop: '-16px' }}>
                    <Spin spinning={smartWidget.isFetching} size="large" style={{ minHeight: 300, height: '100%' }}>
                        <Row gutter={[16, 32]}>
                            <Col xs={24} md={{ span: 16, offset: 4 }}>
                                <DatePicker
                                    defaultValue={dayjs()}
                                    onChange={(newDate) => { this.changeDate(newDate); }}
                                    className="date-picker"
                                    allowClear={false}
                                    format="DD/MM/YYYY" 
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                />
                            </Col>
                            <Col xs={24} md={4} style={{ textAlign: 'right' }}>
                                <Button
                                    type="primary"
                                    onClick={() => { actions.resetChartDataRequest(); history.push('/smart-widget'); }}
                                >
                                    Add Smart Widget
                                </Button>
                            </Col>
                        </Row>
                        <div className="grid-wrapper">
                            <ResponsiveGridLayout
                                // don't use onLayoutChange as it is triggered when screen width change.
                                className="layout"
                                onDragStop={(e) => {
                                    this.saveGridLayoutPositions(e);
                                }}
                                cols={{
                                    lg: 4, md: 4, sm: 1, xs: 1, xxs: 1,
                                }}
                                rowHeight={285}
                                width={x * 5}
                                margin={[24, 24]}
                            >
                                {smartWidget.smartWidgets.map((item, key) => (
                                    <div
                                        key={item.id}
                                        data-grid={{
                                            x: item.x,
                                            y: item.y,
                                            w: item.w,
                                            h: item.h,
                                            isDraggable: true,
                                        }}
                                        onKeyDown={() => this.setState({ adding: true })}>
                                        <SmartWidgetItem
                                            chartKey={key}
                                            getChartData={(f) => { this.gateChartData = f; }}
                                            date={date}
                                            data={item} />
                                    </div>
                                ))}
                            </ResponsiveGridLayout>
                        </div>
                    </Spin>
                </div>
            </div>
        </Layout>
    );
}
