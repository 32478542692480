import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    Input, Checkbox, Spin, Button,
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import GridView from '../../../components/elements/GridView/index';
import * as userActions from '../../../core/user/userActions';

class AccountPermissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchParams: {},
            orderParams: {},
        };

        this.timeout = 0;
    }

    componentDidMount() {
        const { actions, userId } = this.props;
        actions.getAccountPermissionsGridRequest({ user_id: userId });
    }

    changeAccessSetting(access) {
        const { actions, userId } = this.props;
        const { searchParams } = this.state;

        searchParams.has_access = access;
        actions.getAccountPermissionsGridRequest({
            user_id: userId, searchParams,
        });

        this.setState(searchParams);
    }

    searchPermissions(keyword) {
        const { actions, userId } = this.props;
        const { searchParams } = this.state;
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            searchParams.search = keyword;
            actions.getAccountPermissionsGridRequest({
                user_id: userId, searchParams,
            });

            this.setState(searchParams);
        }, 1000);
    }

    changePermission = (permission, access) => {
        const { actions, userId } = this.props;
        const newAccess = access === 1 ? 0 : 1;

        actions.changeUserPermissionRequest({
            access: newAccess, user_id: userId, permission_id: permission.id,
        });
    };

    render() {
        const {
            actions,
            accountPermissions,
            accountPermissionsPagination,
            accountPermissionsIsFetching,
        } = this.props;
        const rightData = accountPermissions.map((d) => ({
            title: d.title,
            access: <Button type="link" onClick={() => this.changePermission(d, parseInt(d.access, 10))}>{parseInt(d.access, 10) === 1 ? <CheckOutlined style={{ color: '#389e0d' }} /> : <CloseOutlined style={{ color: '#f5222d' }} />}</Button>,
        }));

        const gridChange = (pagination, filters, sorter) => {
            const { searchParams, orderParams } = this.state;
            if (pagination && pagination.current === accountPermissionsPagination.currentPage) {
                orderParams[sorter.field] = sorter.order ? sorter.order : false;
                this.setState({ orderParams });
                actions.getAccountPermissionsGridRequest({ page: accountPermissionsPagination.currentPage, searchParams, orderParams });
            }
        };

        const paginationChange = (page) => {
            const { searchParams, orderParams } = this.state;
            if (page !== accountPermissionsPagination.currentPage) {
                actions.getAccountPermissionsGridRequest({ page, searchParams, orderParams });
            }
        };

        return (
            <div className="division-tab-wraper account-permissions">
                <div className="right-side">
                    <div className="search-bar custom-search-bar">
                        <Input placeholder="Search Title" onChange={(data) => this.searchPermissions(data.target.value)} />
                        <Checkbox onChange={(data) => this.changeAccessSetting(data.target.checked)}>
                            Show only account permissions user has access to
                        </Checkbox>
                    </div>
                    <Spin spinning={accountPermissionsIsFetching}>
                        <div className="account-permission-table-wrapper">
                            <GridView
                                data={rightData}
                                onChange={gridChange}
                                pagination={{
                                    total: parseInt(accountPermissionsPagination.totalRecords, 10),
                                    showSizeChanger: false,
                                    pageSize: accountPermissionsPagination.perPage,
                                    onChange: paginationChange,
                                }} />
                        </div>
                    </Spin>
                </div>
            </div>
        );
    }
}

AccountPermissions.defaultProps = {
    // Default props go here
    // userId: null,
};

AccountPermissions.propTypes = {
    actions: PropTypes.object.isRequired,
    accountPermissions: PropTypes.array.isRequired,
    accountPermissionsPagination: PropTypes.object.isRequired,
    accountPermissionsIsFetching: PropTypes.bool.isRequired,
    userId: PropTypes.number.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        accountPermissions: state.user.accountPermissions,
        accountPermissionsPagination: state.user.accountPermissionsPagination,
        accountPermissionsIsFetching: state.user.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AccountPermissions);
