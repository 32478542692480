import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import {
    Checkbox, Row, Col, Spin, 
} from 'antd';
import { Link } from 'react-router-dom';
import { find } from 'underscore';

const antIcon = <LoadingOutlined style={{ fontSize: 10 }} spin />;

const onlineIcon = (
    <div className="online-indicator-box online">
        <span className="text">
            Online
        </span>
    </div>
);

const offlineIcon = (
    <div className="online-indicator-box">
        <span className="text">
            Offline
        </span>
    </div>
);

const loadingIcon = (
    <div className="online-indicator-box loading">
        <Spin spinning />
    </div>
);

const onlySpaces = (str) => /^\s*$/.test(str);

function VideoFormBox({
    image, registration, onSelect, selectedCameras, camera, name, driver, isFetchingStatus,
}) {
    const availableChannels = camera?.available_channels || [];
    const camerasSelection = availableChannels.map((availableChannel) => {
        const { channel } = availableChannel;
        let { label } = availableChannel;
        const id = `${camera.imei}-${channel}-${name}`;
        const currentCamera = find(selectedCameras, (selectedCamera) => selectedCamera.id == id);
        if (!label) {
            const labelChannel = Number(channel) + 1;
            label = `CH${labelChannel}`;
        }
        return (
            <Col
                span={12} 
                key={`KEY-${channel}`} 
                style={{ 
                    textAlign: 'left',
                    marginTop: '4px',
                }}
            >
                <Row 
                    style={{ margin: 0 }} 
                    gutter={[8, 8]}
                >
                    <Col xs={5}>
                        <Checkbox 
                            checked={!!currentCamera} 
                            onChange={(e) => onSelect(id, e.target.checked)} 
                        />
                    </Col>
                    <Col span={19}>
                        <span className='camera-label'>{label}</span>
                    </Col>
                </Row>
            </Col>
        );
    });

    return (
        <div className="video">
            <div className="video__cover">
                {isFetchingStatus
                    ? loadingIcon
                    : (
                        <>
                            {camera.status && camera.status == '1' ? onlineIcon : offlineIcon}
                        </>
                    )}
                <img src={image} className="fitted" alt="Video" />
            </div>
            <div className="video__descr">
                <Row>
                    <span>Asset:</span>
                    <Link
                        to={`/assets/view/${camera.asset_id}`}
                        className="btn-link ml-1"
                    >
                        {name}
                    </Link>
                </Row>
                <Row>
                    <span>Driver:</span>
                    <Link
                        to={`/drivers/view/${camera.user_id}`}
                        className="btn-link ml-1"
                    >
                        {driver}
                    </Link>
                </Row>
                <Row>
                    <span>Reg:</span>
                    <span className='non-link ml-1'>{registration}</span>
                </Row>
                <Row className='mt-2'>
                    {camerasSelection}
                </Row>
            </div>
        </div>
    );
}

VideoFormBox.defaultProps = {
    image: '',
    registration: '',
    onSelect: () => { },
};

VideoFormBox.propTypes = {
    image: PropTypes.string,
    registration: PropTypes.string,
    onSelect: PropTypes.func,
    camera: PropTypes.object.isRequired,
    selectedCameras: PropTypes.array.isRequired,
};
export default VideoFormBox;
