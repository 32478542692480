import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Tooltip, Skeleton, Spin, Row, Col, Card, Radio,
} from 'antd';
import moment from 'moment';
import { isEmpty, isUndefined } from 'underscore';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
    Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip as ToolTipChart,
} from 'recharts';
import Icon from '../elements/Icon';
import '../../pages/Dashboard/dashboard.css';
import './telematics.scss';
import * as assetActions from '../../core/asset/assetActions';
import * as reportActions from '../../core/report/reportActions';
import * as videoActions from '../../core/video/videoActions';
import DOM from '../../pages/Reports/ReportPages/MpgReport/mpgReport';
import DefaultAssetPicture from '../../assets/images/van.png';
import DefaultDrivePicture from '../../assets/images/default-image.png';
import ProgressCircle from '../elements/ProgressCircle';
import cyclearLogo from '../../assets/images/cyclear-logo.png';
import idClearLogo from '../../assets/images/id-clear-no-trademark.jpg';
import averageMpgIcon from '../../assets/images/average-mpg.png';
import journeyScore from '../../assets/images/journey-score.png';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import journeyTime from '../../assets/images/clock.png';
import journeySpeed from '../../assets/images/speed.png';
import * as globalActions from '../../core/global/globalActions';
import { secondsToHms } from '../../core/utils/functions';

const { MAP_SLIDER_INTERVAL, MAP_LIVE_ASSET_DELAY } = require('../../core/constants').default;

const ResponsiveGridLayout = WidthProvider(Responsive);

class AssetSideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
        };
        this.view = DOM;
    }

    handleTimesheetExportButtonClick = () => {
        const { actions, assetId } = this.props;
        if (assetId > 0) {
            actions.getTimesheetReportCsvRequest({
                asset_id: assetId,
                export: true,
            });
        }
    };

    posEqual = (widgets, layout) => {
        let equal = true;
        const assertEqual = (n, m) => {
            equal = !equal ? false : n === m;
        };
        widgets.forEach((a) => {
            const b = layout.find((e) => `${e.i}` === `${a.id}`);
            if (b) {
                assertEqual(a.pos.x, b.x);
                assertEqual(a.pos.y, b.y);
                assertEqual(a.pos.w, b.w);
                assertEqual(a.pos.h, b.h);
            }
        });
        return equal;
    };

    layoutChanged = (layout) => {
        const { actions, loading } = this.props;
        if (this.loaded && !loading) {
            if (!this.posEqual(layout)) {
                const positions = layout.map((e) => ({
                    id: e.i,
                    x: e.x,
                    y: e.y,
                    w: e.w,
                    h: e.h,
                }));
            }
        }
    };

    exportCsv() {
        const { timeSheetReport, actions, assetId } = this.props;
        if (assetId) {
            const data = actions.getTimesheetReportRequest({
                assetId,
                export: true,
            });
        }
    }

    exportRecordsCsv() {
        if (this.props.isExportingCsv) {
            return;
        }
        const { actions, assetId, selectedDate } = this.props;
        if (assetId) {
            const date = moment(selectedDate, 'YYYYMMDD').format('YYYY-MM-DD');
            const data = actions.getCsvAssetRecordsByDayRequest({
                assetId,
                date,
            });
        }
        return true;
    }

    handleHistoricVideosClick() {
        const {
            actions, assetReg, selectedDate, selectedTime,
        } = this.props;

        const date = moment(selectedDate, 'YYYYMMDD').format('YYYY-MM-DD');
        const timeFrom = moment(selectedTime, 'HH:mm').subtract('30', 'minutes').format('HH:mm:00');
        const timeTo = moment(selectedTime, 'HH:mm').add('30', 'minutes').format('HH:mm:00');

        actions.updateVideoSearch({
            assetReg, date, timeFrom, timeTo,
        });
    }

    render() {
        const {
            assetId, backLinkClick, device, assetName, distance, assetReg,
            assetMake, assetModel, assetPicture, profilePicture, assetDriver,
            assetLocation, selectedDate, selectedTime, assets, user, lastDataReceived,
            actions, isFetching, telematicsData, sliderValue: selectedSliderValue,
            deviceLocations, timeSheetReport, deviceDetailedLocationInfo,
        } = this.props;
        let idleTime = 0;
        let trueIdleTime = 0;
        let score = 0;
        let timeDrivenFormatted = 0;
        let averageSpeed = 0;
        let averageMpg = 0;
        let fuelLevel = 0;
        let driverAverageScore = '';

        if (!isEmpty(telematicsData)) {
            driverAverageScore = telematicsData.driverAverageScore;
            if (!isEmpty(telematicsData.journey)) {
                idleTime = telematicsData.journey.idle_time;
                trueIdleTime = telematicsData.journey.true_idle_time;
                score = telematicsData.journey.score;
                timeDrivenFormatted = telematicsData.journey.time_driven_formatted;
                averageSpeed = telematicsData.journey.average_speed;
                averageMpg = telematicsData.journey.mpg;
                fuelLevel = telematicsData.journey.fuel_level;
            }
        }

        const chartData = [
            {
                name: 'Idle Time',
                pv: idleTime || 0,
            },
            {
                name: 'True Idle',
                pv: trueIdleTime || 0,
            },
        ];

        let lastDriven = null;
        let location = null;
        const assetSelectedLocation = null;
        const findAssetSelectedLocation = null;
        /// ///////////////////////////////
        const hoursAndMinutesCombined = ((selectedSliderValue * MAP_SLIDER_INTERVAL) / 60);
        let hours = Math.floor(hoursAndMinutesCombined);
        if (hours <= 9) {
            hours = (`0${hours}`).slice(-2);
            hours = (parseInt(hours) > 23 ? (`0${hours}`).slice(-3) : hours);
        }
        let minutes = Math.floor((hoursAndMinutesCombined % 1) * 60);
        if (minutes <= 9) {
            minutes = (`0${minutes}`).slice(-2);
        }

        if (!isEmpty(deviceDetailedLocationInfo)) {
            lastDriven = moment(`${deviceDetailedLocationInfo.last_data_received}:00`, 'YYYY-MM-DD HH:mm:ss')
                .format('DD/MM/YYYY HH:mm');
            if (deviceDetailedLocationInfo.marker) {
                location = deviceDetailedLocationInfo.marker.location;
            }
        }

        if (!(assets && assets.length > 0)) {
            if (!isUndefined(lastDataReceived) && lastDataReceived !== null) {
                lastDriven = moment(`${lastDataReceived}:00`, 'YYYY-MM-DD HH:mm:ss')
                    .format('DD/MM/YYYY HH:mm');
            }
        }

        const realWindowWidth = window.innerWidth - 40 > 1200 ? 1200 : window.innerWidth - 40;
        const x = (realWindowWidth) / 5;

        const linkExport = (
            <Link to="/map" onClick={this.handleTimesheetExportButtonClick}>
                <span className="dataitem__icon">
                    <Icon name="list" />
                </span>
            </Link>
        );

        return (
            <div style={{ width: 360 }} className="asset-sidebar-view">
                <div className="filter__sidebar">
                    <Spin spinning={false}>
                        <div className="filter__sidebar-inner" style={{ height: '86.5vh' }}>
                            <div className="driver__header">
                                <div className="driver__name">
                                    <button type="button" className="btn btn-empty backlink" onClick={backLinkClick}>
                                        <Icon name="arrow-left" />
                                    </button>
                                    <span>
                                        <Tooltip title={assetName}>
                                            {assetName && assetName.length > 5 ? `${assetName.substring(0, 5)}...` : assetName}
                                        </Tooltip>
                                    </span>
                                    <span className="ml-1 secondary-text">({distance} Mi)</span>
                                </div>
                                <div className="driver-cars">
                                    <span>{assetReg}</span>
                                    <span>{assetMake} {assetModel}</span>
                                </div>
                            </div>
                            <div className="driver-info">
                                <div className="driver-info__cell">
                                    <span className="driver-info__status">
                                        <Icon name="clock" />
                                        LAST DRIVEN
                                    </span>
                                    <span className="driver-info__label">{lastDriven}</span>
                                </div>
                                <div className="driver-info__cell">
                                    <span className="driver-info__status">
                                        <Icon name="location" />
                                        LOCATION
                                    </span>
                                    <span className="driver-info__label">{location}</span>
                                </div>
                                <div className="driver-info__cell">
                                    <span className="driver-info__status">
                                        <Icon name="person" />
                                        LAST DRIVER
                                    </span>
                                    <span className="driver-info__label">{assetDriver}</span>
                                </div>
                            </div>

                            <Radio.Group value="large" onChange={this.handleSizeChange}>
                                {user.permissions.live_video === true
                                    ? (
                                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                        <Tooltip placement="topLeft" title="Live Video Feed">
                                            <Radio.Button value="default">
                                                <a
                                                    href={`/video/live-videos/${assetId}?imei=${device}`}
                                                    style={{ width: '100%' }}>
                                                    <span className="dataitem__icon">
                                                        <Icon name="road" />
                                                    </span>
                                                </a>
                                            </Radio.Button>
                                        </Tooltip>

                                    )
                                    : null}

                                {user.permissions.historic_video_downloading === true
                                    ? (
                                        <Tooltip placement="topLeft" title="Historic Videos">
                                            <Radio.Button value="default">
                                                <Link
                                                    to="/video/cached-videos"
                                                    onClick={() => { this.handleHistoricVideosClick(); }}>
                                                    <span className="dataitem__icon">
                                                        <Icon name="clock" />
                                                    </span>
                                                </Link>
                                            </Radio.Button>
                                        </Tooltip>

                                    )
                                    : null}

                                {user.permissions.event_searching === true
                                    ? (
                                        <Tooltip placement="topLeft" title="Event History">
                                            <Radio.Button value="small">
                                                <Link
                                                    to="/events/search"
                                                    onClick={() => {
                                                        actions.updateEventSearch({
                                                            assetId,
                                                            dateFrom: selectedDate,
                                                        });
                                                    }}>
                                                    {/* <Link to={`/map/${device}/alert-history/${selectedDate}`}> */}
                                                    <span className="dataitem__icon">
                                                        <Icon name="events" />
                                                    </span>
                                                </Link>
                                            </Radio.Button>
                                        </Tooltip>

                                    )
                                    : null}

                                <Tooltip placement="topLeft" title="Timesheet Report">
                                    <Radio.Button value="small">
                                        {/* <Link to="/map" onClick={() => this.exportCsv()}>
                                            <span className="dataitem__icon">
                                                <Icon name="list" />
                                            </span>
                                                </Link>
                                        <ExcelFile element={<div><span className="dataitem__icon">
                                            <Icon name="list" />
                                        </span></div>}>
                                            {customExcelSheet}
                                                </ExcelFile>

                                        <Link to="/map" onClick={() => this.exportCsv()}>
                                            <span className="dataitem__icon">
                                                <Icon name="list" />
                                            </span>
                                        </Link>
                                        <ExcelFile element={<span className="dataitem__icon"><Icon name="list" /></span>}>
                                            <ExcelSheet dataSet={multiDataSet} name="Timesheet_Report" />
                                        </ExcelFile>
                                        */}
                                        {linkExport}
                                    </Radio.Button>
                                </Tooltip>

                                <Tooltip placement="topLeft" title="Download records for the day">
                                    <Radio.Button
                                        value="default"
                                        disabled={this.props.isExportingCsv}
                                        onClick={() => {
                                            this.exportRecordsCsv();
                                        }}>

                                        <span className="dataitem__icon">
                                            <Spin size="small" spinning={this.props.isExportingCsv}>
                                                <Icon name="download" />
                                            </Spin>
                                        </span>

                                    </Radio.Button>
                                </Tooltip>

                            </Radio.Group>
                            <div className="filter__sidebar-data">
                                <div className="" style={{ margin: '10px' }}>
                                    <Row
                                        gutter={[24, 24]}
                                        className="data-row event-view-driver-info"
                                        ref={this.pdfDocumentRef}>
                                        <div className="data-block">
                                            {isFetching
                                                ? <Skeleton />
                                                : (
                                                    <>
                                                        <Col
                                                            xs={12}
                                                            sm={4}
                                                            md={12}
                                                            lg={12}>
                                                            <img
                                                                className="driver-info-image"
                                                                alt="[not-set]"
                                                                src={deviceDetailedLocationInfo && deviceDetailedLocationInfo.asset_picture
                                                                    ? deviceDetailedLocationInfo.asset_picture
                                                                    : (assetPicture || DefaultAssetPicture)} />
                                                        </Col>
                                                        <Col xs={12} sm={4} md={12} lg={12}>
                                                            <img
                                                                className="driver-info-image"
                                                                alt="[not-set]"
                                                                src={deviceDetailedLocationInfo && deviceDetailedLocationInfo.user_picture
                                                                    ? deviceDetailedLocationInfo.user_picture
                                                                    : (profilePicture || DefaultDrivePicture)} />
                                                        </Col>
                                                    </>
                                                )}

                                        </div>
                                    </Row>

                                </div>

                                <div className="grid-wrapper">
                                    <ResponsiveGridLayout
                                        className="layout"
                                        isResizable
                                        onLayoutChange={(e) => {
                                            this.forceUpdate();
                                            this.layoutChanged(e);
                                        }}
                                        cols={{
                                            lg: 2,
                                            md: 2,
                                            sm: 2,
                                            xs: 2,
                                            xxs: 2,
                                        }}
                                        rowHeight={100}
                                        width={x * 2}>

                                        <div
                                            className="widget-container telematics-data-card-col"
                                            key={1}
                                            data-grid={{
                                                x: 0,
                                                y: 0,
                                                w: 1,
                                                h: 2,
                                                isDraggable: true,
                                                isResizable: true,
                                            }}>
                                            <div className="telematics-data-card-title">
                                                <span className="telematics-data-card-title-text">Driver Score</span>
                                            </div>
                                            <Card className="telematics-data-card progress-circle-container" style={{ height: 200 }}>
                                                <ProgressCircle
                                                    width={100}
                                                    className="progress-circle driver-score"
                                                    percent={parseInt(driverAverageScore, 10)}
                                                    type="circle"
                                                    strokeColor="#FF8733"
                                                    strokeWidth={11} />
                                            </Card>
                                        </div>

                                        <div
                                            className="widget-container telematics-data-card-col"
                                            key={2}
                                            data-grid={{
                                                x: 1,
                                                y: 0,
                                                w: 1,
                                                h: 1,
                                                isDraggable: true,
                                            }}>

                                            <div className="telematics-data-card-title">
                                                <span className="telematics-data-card-title-text">Activations </span>
                                            </div>
                                            <Card className="telematics-data-card no-padding" style={{ height: 80 }}>

                                                <Row gutter={[24, 24]} style={{ marginTop: '10px' }}>
                                                    <Col xs={8} sm={4} md={6} lg={8}>
                                                        <div className="activations-icon">
                                                            <img src={cyclearLogo} alt="journey score" />
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} sm={4} md={6} lg={10} style={{ padding: '10px 0px 10px 0px' }}>
                                                        <div className="activations-title">
                                                            Cyclear Activations
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} sm={4} md={6} lg={2}>
                                                        <div className="activations-stat">
                                                            0
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </div>

                                        <div
                                            className="widget-container telematics-data-card-col"
                                            key={3}
                                            data-grid={{
                                                x: 1,
                                                y: 1,
                                                w: 1,
                                                h: 1,
                                                isDraggable: true,
                                            }}>

                                            <div className="telematics-data-card-title">
                                                <span className="telematics-data-card-title-text">Activations </span>
                                            </div>
                                            <Card className="telematics-data-card no-padding" style={{ height: 80 }}>

                                                <Row gutter={[24, 24]} style={{ marginTop: '10px' }}>
                                                    <Col xs={8} sm={4} md={6} lg={8}>
                                                        <div className="activations-icon">
                                                            <img src={idClearLogo} alt="journey score" />
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} sm={4} md={6} lg={10} style={{ padding: '10px 0px 10px 0px' }}>
                                                        <div className="activations-title">
                                                            IDClear Activations
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} sm={4} md={6} lg={2}>
                                                        <div className="activations-stat">
                                                            0
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Card>
                                        </div>

                                        <div
                                            className="widget-container telematics-data-card-col"
                                            key={4}
                                            data-grid={{
                                                x: 0,
                                                y: 1,
                                                w: 2,
                                                h: 2,
                                                isDraggable: true,
                                                isResizable: true,
                                            }}>
                                            <div className="telematics-data-card-title">
                                                <span
                                                    className="telematics-data-card-title-text">Idle
                                                </span>
                                            </div>
                                            <Card
                                                className="telematics-data-card no-padding idle">
                                                <BarChart
                                                    className="idle-chart"
                                                    width={280}
                                                    height={180}
                                                    data={chartData}
                                                    barSize={30}
                                                    barGap={5}
                                                    margin={{}}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <ToolTipChart formatter={(value) => secondsToHms(value)} />
                                                    <Bar dataKey="pv" stackId="a" fill="#FF8733" />
                                                    <Bar dataKey="uv" stackId="a" fill="#eff3f5" />
                                                </BarChart>
                                            </Card>
                                        </div>

                                        {/* <div
                                            className="widget-container telematics-data-card-col fuel-progress middle"
                                            key={5}
                                            data-grid={{
                                                x: 0,
                                                y: 1,
                                                w: 1,
                                                h: 1,
                                                isDraggable: true,
                                            }}>

                                            <Card className="telematics-data-card no-padding" style={{ height: 80 }}>
                                                <div className="telematic-box-stat-icon">
                                                    <img src={fuelIcon} alt="journey score" />
                                                </div>
                                                <div className="telematic-box-stat-text">
                                                    FUEL LEVEL
                                                </div>
                                                <div className="telematic-box-stat">
                                                    {fuelLevel}%
                                                    <Progress
                                                        className="telematics-fuel-level-progress"
                                                        type="line"
                                                        percent={parseFloat(fuelLevel)}
                                                        showInfo={false}
                                                        strokeColor="#FF8733"
                                                        strokeLinecap="square"
                                                        strokeWidth={10} />

                                                </div>

                                            </Card>
                                        </div> */}

                                        <div
                                            className="widget-container telematics-data-card-col"
                                            key={6}
                                            data-grid={{
                                                x: 0,
                                                y: 1,
                                                w: 1,
                                                h: 1,
                                                isDraggable: true,
                                            }}>
                                            <Card className="telematics-data-card no-padding" style={{ height: 80 }}>
                                                <div className="telematic-box-stat-icon">
                                                    <img src={averageMpgIcon} alt="journey score" />
                                                </div>
                                                <div className="telematic-box-stat-text">
                                                    AVERAGE MPG
                                                </div>
                                                <div className="telematic-box-stat">
                                                    {averageMpg}
                                                </div>
                                            </Card>
                                        </div>

                                        <div
                                            className="widget-container telematics-data-card-col"
                                            key={7}
                                            data-grid={{
                                                x: 0,
                                                y: 1,
                                                w: 2,
                                                h: 2,
                                                isDraggable: true,
                                                isResizable: true,
                                            }}>
                                            <div className="telematics-data-card-title">
                                                <span
                                                    className="telematics-data-card-title-text">Journey Info
                                                </span>
                                            </div>

                                            <Card className="telematics-data-card no-padding">
                                                <table className="journey-info">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="journey-score-icon"><img
                                                                    src={journeyScore}
                                                                    alt="journey score" />
                                                                </div>
                                                                Journey Score
                                                            </td>
                                                            <td>{score}%</td>
                                                            <td><ArrowUpOutlined style={{ color: '#52c41a' }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="journey-score-icon"><img
                                                                    src={journeyTime}
                                                                    alt="journey score" />
                                                                </div>
                                                                Journey time
                                                            </td>
                                                            <td>{timeDrivenFormatted}</td>
                                                            <td><ArrowDownOutlined style={{ color: 'red' }} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="journey-score-icon"><img
                                                                    src={journeySpeed}
                                                                    alt="journey score" />
                                                                </div>
                                                                Average speed
                                                            </td>
                                                            <td>{averageSpeed}</td>
                                                            <td><ArrowUpOutlined style={{ color: '#52c41a' }} /></td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </Card>

                                        </div>

                                    </ResponsiveGridLayout>
                                </div>

                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        );
    }
}

AssetSideBar.defaultProps = {
    isFetching: false,
};

AssetSideBar.propTypes = {
    assetId: PropTypes.number,
    backLinkClick: PropTypes.func,
    assetName: PropTypes.string,
    distance: PropTypes.number,
    assetReg: PropTypes.string,
    assetMake: PropTypes.string,
    assetModel: PropTypes.string,
    assetDriver: PropTypes.string,
    assetLocation: PropTypes.string,
    assetPicture: PropTypes.string,
    profilePicture: PropTypes.string,
    user: PropTypes.object,
    timeSheetReport: PropTypes.array,
    telematicsData: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    lastDataReceived: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        timeSheetReport: state.asset.timeSheetReport,
        deviceDetailedLocationInfo: state.device.deviceDetailedLocationInfo,
        isExportingCsv: state.asset.isExportingCsv,
        // isFetching: state.asset.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
            ...videoActions,
            ...globalActions,
            ...reportActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetSideBar);
