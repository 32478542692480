/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../../../components/layout/Layout';
import FilterSidebar from '../../../../components/FilterSidebar';
import Icon from '../../../../components/elements/Icon';
import ReportFilter, { DATE_MODE_RANGE, DATA_MODE_ASSETS_OR_DIVISIONS } from '../../ReportFilter';
import ReportTab from './tabs/ReportTab';

export default function () {
    const {
        filterSidebar, filters
    } = this.state;
    const { user } = this.props;
    
    return (
        <Layout
            className="events"
            title={<div>Geo-fence Entry/Exit Report</div>}
            headerTitle={<div>Geo-fence Entry/Exit Report</div>}
        >
            <div className={`content-wrap${user?.userCompany?.currentAlert ? '-with-banner' : ''}`}>
                <FilterSidebar displayFilter={filterSidebar}>
                    <ReportFilter
                        onSubmit={(value) => { this.onGeofenceTabFilterChange(value) }}
                        onChangeViewType={this.onChangeViewType.bind(this)}
                        filters={filters}
                        showDivision
                        showAssets
                        showGeoFence
                        allowDivisionByPass
                        dataMode={DATA_MODE_ASSETS_OR_DIVISIONS}
                        dateMode={DATE_MODE_RANGE}
                        clearGeofence={this.clearGeoFence.bind(this)}
                        geofenceDrawingEnabled={this.state.geofenceDrawingEnabled}
                        toggleDrawGeoFence={this.toggleDrawGeoFence.bind(this)}
                        geofenceSelected={this.predefinedGeoFenceSelected.bind(this)}
                        saveGeoFence={this.saveGeoFence.bind(this)}
                        geofenceSaved={this.state.geofenceSaved}
                    />
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <div className="section__action-bar" />
                                <ReportTab
                                    toggleFilterSidebar={() => this.setState({ filterSidebar: !filterSidebar })}
                                    onPageChange={this.onPaginationChange}
                                    onExportCSV={this.onExportCSV}
                                    geFenceCompleted={this.geFenceCompleted}
                                    reportHasBeenFetched={this.state.reportHasBeenFetched}
                                    handleMapRef={this.handleMapRef}
                                    newGeoFencePath={this.state.newGeoFencePath}
                                    toggleDrawGeoFence={this.toggleDrawGeoFence.bind(this)}
                                    showSaveGeofence={this.state.showSaveGeofence}
                                    getDivisions={this.getDivisions.bind(this)}
                                    cancelGeoFence={this.cancelGeoFence.bind(this)}
                                    onMapMounted={this.onMapMounted.bind(this)}
                                    createNewTrigger={this.createNewTrigger}
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
