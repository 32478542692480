/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { Spin, Button, Select } from 'antd';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';


import Icon from '../../components/elements/Icon';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import './departmentsView.scss';
import LeftSide from './partials/LeftSide';
import GridView from '../../components/elements/GridView';


const { Option } = Select;

export default function () {
    const {
        departmentUsers,
        departmentUsersPagination,
        departmentUsersIsFetching,
        departmentDetail,
        otherUsers,
        user,
    } = this.props;

    const data = departmentUsers.map((d) => ({
        id: d.id,
        name: d.name,
        email: d.email,
        role: d.role,
        status: parseInt(d.status, 10) === 1 ? 'Active' : 'Not Active',
        actions: (
            <Button className="btn btn-empty ml-2" onClick={() => this.removeUser(d.id)}>
                <CloseOutlined style={{ color: '#f5222d' }} />
            </Button>
        ),
    }));

    return (
        <Layout
            data-test="pages-departments-view"
            className="events-view"
            title={<div>AssetsView</div>}
            headerTitle={<div>AssetsView Page</div>}
            showFooter={false}>
            <div className={`content-wrap${user?.userCompany?.currentAlert ? '-with-banner' : ''}`}>
                <FilterSidebar />
                <div className="content">
                    <Spin spinning={departmentUsersIsFetching}>
                        <div className="content-inner">
                            <section className="section">
                                <div className="section__body">

                                    <div className="right-header-wrapper">
                                        <h4 className="right-header-head"><span style={{ fontWeight: 'bold' }}>Department details for:</span> {departmentDetail.name}</h4>
                                        <Button
                                            onClick={() => this.submitUpdateDepartmentForm()}
                                            type="primary"
                                            className="btn">
                                            <SaveOutlined />
                                            <span>
                                                Save
                                            </span>
                                        </Button>
                                    </div>
                                    <div className="department-wrapper">

                                        <div className="left-side-wrapper">
                                            <LeftSide registerSubmitHandler={(f) => { this.submitUpdateDepartmentForm = f; }} />
                                        </div>
                                        <div className="right-side-wrapper">
                                            <div>
                                                <GridView
                                                    data={data}
                                                    onChange={this.onGridChange}
                                                    sortableColumns={[]}
                                                    pagination={{
                                                        total: parseInt(departmentUsersPagination.totalRecords, 10) || 0,
                                                        pageSize: parseInt(departmentUsersPagination.perPageCount, 10) || 10,
                                                        showSizeChanger: false,
                                                        onChange: this.paginationChange,
                                                    }} />
                                                <div className="assignment-section">
                                                    <h3 className="assignment-header">Assign users to this department</h3>
                                                    <Select value="" className="assignment-select" showSearch onChange={() => null} allowClear>
                                                        {otherUsers.map((item) => (
                                                            <Option key={`other-users-${item.id}`} value={item.full_name || item.name}>
                                                                <div className="button-option">
                                                                    {item.full_name || item.name} <Button onClick={() => this.assignUser(item.id)}>Add</Button>
                                                                </div>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Spin>
                </div>
            </div>
        </Layout>
    );
}
