import React from 'react';
import { Layout as AntLayout, Row, Col } from 'antd';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import { Link } from 'react-router-dom';
import './notFound.scss';
import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';

export default function () {
    return (
        <Layout
            data-test="pages-notfound"
            showHeader={false}
            className="not-found"
            title={<div>Page not found</div>}
            headerTitle={<div>Page not found</div>}
            showFooter={false}>
            <div className="header">
                <AntLayout.Header className="header__inner">
                    <Row type="flex" justify="space-between">
                        <Col>
                            <div className="header__logos">
                                <img src={srcFleetclearLogo} className="header__logo-fleet" alt="Fleetclear" />
                            </div>
                        </Col>
                    </Row>
                </AntLayout.Header>
            </div>
            <div className="content-wrap">
                <FilterSidebar />
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <p className="heading">Page Not Found</p>
                                <p className="link">
                                    <Link to="/map">Home</Link>
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
