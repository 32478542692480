/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Spin, Dropdown, Button, Menu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { isEmpty } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as reportActions from '../../../../../core/report/reportActions';
import Icon from '../../../../../components/elements/Icon';
import history from '../../../../../core/utils/history';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    componentDidMount() {
        const {
            actions, match, speedingReportBack, profile,
        } = this.props;
        if (speedingReportBack.referrer === 'speeding-report') {
            actions.getSpeedingReportRequest({
                referrer: '', asset_id: speedingReportBack.asset_id, date_from: speedingReportBack.date_from, date_to: speedingReportBack.date_to, assetId: speedingReportBack.assetId, page: speedingReportBack.page,
            });
        }
    }

    render() {
        const {
            speedingReport,
            isFetching,
            pagination,
            toggleFilterSidebar,
            actions,
            speedingReportBack,
            reportHasBeenFetched,
            metricUnit,
        } = this.props;

        const convertedSpeedingReport = [];
        speedingReport.forEach((report) => {
            const newReport = {};
            const keys = Object.keys(report);

            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = report[key];

                if (metricUnit && value != null) {
                    // convert miles to km
                    switch (key) {
                    case 'Max Speed':
                    case 'Speed Limit':
                    case 'Distance':
                    case 'Avg':
                        newReport[key] = (value * 1.609344).toFixed(2).toString();
                        break;
                    default:
                        newReport[key] = value.toString();
                    }
                } else {
                    newReport[key] = value != null ? value.toString() : null;
                }
            }
            convertedSpeedingReport.push(newReport);
        });

        const listReports = convertedSpeedingReport.map((speedingReportLocal) => {
            const tempReport = {
                ...speedingReportLocal,
            };

            tempReport.actions = (
                !isEmpty(speedingReportLocal.record_id) && !isEmpty(speedingReportLocal.device_id)
                    ? (
                        <Link
                            to={{
                                pathname: `/events/custom-view/${speedingReportLocal.event_id}/${speedingReportLocal.device_id}`,
                            }}>
                            <Icon name="chevron-right" className="ml-2" />
                        </Link>
                    )
                    : null
            );
            // tempEvent.asset_reg = event.registration;
            delete tempReport.device_id;
            delete tempReport.event_id;
            delete tempReport.record_id;
            return tempReport;
        });
        const exportableColumns = listReports && listReports[0] ? (Object.keys(listReports[0]).filter((oK) => oK.toLowerCase() != 'actions')) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table">
                    {isEmpty(listReports)
                        ? reportHasBeenFetched
                            ? <CustomEmpty />
                            : <CustomEmpty type={CustomEmptyType.Report} />
                        : (
                            <>
                                <Dropdown overlay={(
                                    <Menu onClick={this.props.setUnit}>
                                        <Menu.Item key="imperial">Miles (mph)</Menu.Item>
                                        <Menu.Item key="metric">Kilometres (km/h)</Menu.Item>
                                    </Menu>
                                )}>
                                    <Button 
                                        type='primary'
                                        className='export-dropdown-button'
                                        style={{ 
                                            right: 96, 
                                            top: -14 
                                        }}
                                    >
                                        {metricUnit ? "Kilometres (km/h)" : "Miles (mph)"}
                                        <DownOutlined />
                                    </Button>
                                </Dropdown>
                                <Button
                                    type="primary"
                                    className='export-csv-button'
                                    style={{
                                        top: -14,
                                        right: 0,
                                    }}
                                    disabled={this.props.isExportingSpeedingReportCsv}
                                    onClick={() => { this.props.onExportCSV(); }}
                                >
                                    <Spin 
                                        size="small" 
                                        spinning={this.props.isExportingSpeedingReportCsv}
                                    >
                                        Export
                                    </Spin>
                                </Button>
                                <br />
                                <GridView
                                    exportableColumns={exportableColumns}
                                    data={listReports}
                                    onChange={this.onTableChange}
                                    hiddenColumns={['ID']}
                                    pagination={{
                                        showSizeChanger: false,
                                        total: parseInt(pagination.totalRecords, 10),
                                        pageSize: pagination.perPage,
                                        onChange: this.onPageChange,
                                    }} />
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    speedingReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    speedingReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        speedingReport: state.report.speedingReport,
        isFetching: state.report.isFetching,
        profile: state.user.profile,
        speedingReportBack: state.report.speedingReportBack,
        isExportingSpeedingReportCsv: state.report.isExportingSpeedingReportCsv,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
