import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import DeviceTimelineHeader from '../../../DeviceTimelineHeader';

class TableView extends Component {
    render() {
        const {
            dataSource, columns, pagination, onChange, onRowClick, id, expandedRowRender, scroll,
        } = this.props;

        const keyedData = dataSource.map((dataItem, dataItemIndex) => {
            dataItem.key = `scorch-data-row-${dataItemIndex}`;
            return dataItem;
        });
        let actionsIndex = -1;
        let mappedColumns = columns.map((column, i) => {
            if (column.title === 'Timeline') {
                return {
                    dataIndex: 'timeline',
                    exportable: false,
                    key: 'timeline',
                    sortDirections: ['descend', 'ascend'],
                    sorter: false,
                    title: () => (
                        <DeviceTimelineHeader reportLength={keyedData.length} />
                    ),
                };
            } else {
                if (column.dataIndex === '_actions' || column.dataIndex === 'actions' || column.dataIndex === 'action') {
                    actionsIndex = i;
                }
                return column;
            }
        });
        if (actionsIndex > -1) {
            const actionsCol = mappedColumns[actionsIndex];
            actionsCol.title = () => (
                <></>
            );
            mappedColumns.splice(actionsIndex, 1);
            mappedColumns.unshift(actionsCol);
        }
        mappedColumns = mappedColumns.filter((column) => column.dataIndex !== 'id');
        let actualScroll = scroll;
        if (!actualScroll) {
            actualScroll = {
                x: 'max-content',
                y: pagination ? 'calc(100vh - 320px)' : 'calc(100vh - 280px)',
            };
        }

        return (
            <Table
                id={id}
                onRow={(record, recordIndex) => ({
                    onClick: (e) => {
                        const isMac = navigator.userAgent.toLowerCase().indexOf('mac') > -1;
                        onRowClick(record, isMac ? e.metaKey : e.ctrlKey);
                    },
                    onMouseDown: (e) => {
                        if (e.button === 1) { // middle click
                            onRowClick(record, true);
                        }
                    },
                })}
                onChange={onChange}
                pagination={pagination}
                dataSource={keyedData}
                columns={mappedColumns}
                expandedRowRender={expandedRowRender} 
                scroll={actualScroll}
            />
        );
    }
}

TableView.defaultProps = {
    dataSource: [],
    columns: [],
    pagination: {},
    onChange: () => {},
    onRowClick: () => {},
    id: '',
    expandedRowRender: null,
};

TableView.propTypes = {
    dataSource: PropTypes.array,
    columns: PropTypes.array,
    pagination: PropTypes.object,
    onChange: PropTypes.func,
    onRowClick: PropTypes.func,
    id: PropTypes.string,
    expandedRowRender: PropTypes.func,
};

export default TableView;
