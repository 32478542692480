import React from 'react';
import { Col, Row, Spin, Button, Empty, Slider } from 'antd';
import { StepBackwardOutlined, StepForwardOutlined, DownloadOutlined } from '@ant-design/icons';
import { map, isEmpty } from 'underscore';

import Icon from '../../../../components/elements/Icon';


import './trimVideo.scss';
import CustomEmpty, { CustomEmptyType } from '../../../../components/CustomEmpty';

export default function () {
    const { video } = this.props;
    const { currentVideoIndex, availablevideosCount, start, middle, end, hideVideos, activeVideo } = this.state;

    const previousExtraClass = currentVideoIndex === 0 ? 'notActive' : '';
    const nextExtraClass = (currentVideoIndex + 1) >= availablevideosCount ? 'notActive' : '';

    return (
        <div className="live-video">
            {activeVideo !== null ?
                <div className="overlay" />
                : null
            }
            <Row gutter={16}>
                <Button
                    className="back-button"
                    type="link"
                    onClick={() => {
                        const { actions, setShouldShowSidebar } = this.props;
                        actions.selectSingleSearchedVideoRequest({});
                        setShouldShowSidebar(true);
                    }}>
                    <Icon name="chevron-left" />
                    <span className="back-button-text">Back to video selection</span>
                </Button>
                <Spin spinning={video.isFetching} size="large" style={{ minHeight: 300, height: '100%' }}>

                    <div className="videoMainBlock">
                        <div className="leftMenu">
                            <Button
                                className={`menuButton ${previousExtraClass}`}
                                type="link"
                                onClick={() => {
                                    this.loadPreviousVideo();
                                }}>
                                <StepBackwardOutlined style={{ color: 'var(--cool-gray-80)', fontSize: '40px' }} />
                            </Button>
                        </div>
                        <div className="videosContentWrapper">
                            <Spin spinning={hideVideos} size="large" style={{ minHeight: 300, height: '100%', width: '100%' }}>
                                {!hideVideos ? (
                                    <div className="videosContentInner">
                                        <Row
                                            gutter={0}
                                            type="flex"
                                            justify="space-between"
                                            className="data-row">
                                            {map(video.searchedSingleVideoChannels, ((channel, key) => (
                                                channel ? this.renderVideo(channel, key) : null
                                            )))}
                                        </Row>

                                        {!video.isFetching && isEmpty(video.searchedSingleVideoChannels) ?
                                            <CustomEmpty type={CustomEmptyType.Videos} />
                                            : null}
                                    </div>
                                )
                                    : null}
                            </Spin>
                        </div>
                        <div className="rightMenu">
                            <Button
                                className={`menuButton ${nextExtraClass}`}
                                type="link"
                                onClick={() => {
                                    this.loadNextVideo();
                                }}>
                                <StepForwardOutlined style={{ color: 'var(--cool-gray-80)', fontSize: '40px' }} />
                            </Button>
                        </div>
                    </div>
                    <div className="video-controls-block">
                        <Slider
                            tipFormatter={this.formatTooltip}
                            range
                            tooltipVisible
                            value={[start, middle, end]}
                            defaultValue={[0, 0, 100]}
                            onChange={this.sliderChangeValue}
                            step={0.1}
                            allowCross={false} />
                    </div>
                    <div className="custom-menu-block">
                        <Button
                            className="downloadButton"
                            type="link"
                            onClick={() => {
                                this.trimVideo();
                            }}>
                            <DownloadOutlined style={{ color: 'var(--cool-gray-80)', fontSize: '28px', position: 'absolute' }} /> <span style={{ paddingLeft: 25 }}>Trim & Download</span>
                        </Button>
                    </div>
                </Spin>
            </Row>
        </div>
    );
}
