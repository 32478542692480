import React from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Button, Spin } from 'antd';
import { Redirect, Link } from 'react-router-dom';
import srcISSLogo from '../../assets/images/iss-logo-silver-black.png';
import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';
import './resetPassswordForm.scss';

import Icon from '../elements/Icon';

const FormItem = Form.Item;

export default function () {
    const {
        user, form, isValidResetPassword, isSucessResetPassword, isFetching,
    } = this.props;
    const { getFieldDecorator } = form;

    // if (!isValidResetPassword && !this.state.isValidResetPassword) return <Redirect to='/' />;
    if (isSucessResetPassword && isValidResetPassword) return <Redirect to="/" />;
    return (
        (<Spin spinning={isFetching}>
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <div className="d-flex dir-column align-center text-center">
                        <img src={srcISSLogo} className="login__logo-iss" alt="ISS" />
                        <img src={srcFleetclearLogo} className="login__logo-fleet" alt="Fleetclear" />
                        <h1 className="login__heading">Reset Password</h1>
                    </div>
                    <Form onSubmit={this.handleSubmit} layout="vertical" className="form mt-3">
                        {/* <FormItem label="Your old password:">
                {getFieldDecorator('old_password', {
                    rules: [
                        {
                            required: true,
                            message: 'Please enter old password!',
                        }
                    ],
                })(<Input type="password" size="large" />)}
            </FormItem> */}
                        <FormItem>
                            {getFieldDecorator('new_password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please enter new password!',
                                    },
                                    {
                                        min: 8,
                                        message: 'A new password must be at least 8 characters length!\n\r',
                                    },
                                    {
                                        pattern: /\d+/,
                                        message: 'Must contain at least 1 digit\n\r',
                                    },
                                    {
                                        pattern: /[A-Z]+/,
                                        message: 'Must contain at least 1 uppercase character\n\r',
                                    },
                                    {
                                        pattern: /[a-z]+/,
                                        message: 'Must contain at least 1 lowercase character\n\r',
                                    },
                                    {
                                        pattern: /[!#$@%\-_=+<>\'"`£^&¬\.*\(\)\[\]\?:;\/,{}]/,
                                        message: 'Must contain at least 1 special character',
                                    },

                                ],
                            })(<Input placeholder="Your new password" type="password" size="large" />)}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('new_password_confirm', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please confirm new password!',
                                    },
                                    {
                                        min: 8,
                                        message: 'A new password must be at least 8 characters length!\n\r',
                                    },
                                    {
                                        pattern: /\d+/,
                                        message: 'Must contain at least 1 digit\n\r',
                                    },
                                    {
                                        pattern: /[A-Z]+/,
                                        message: 'Must contain at least 1 uppercase character\n\r',
                                    },
                                    {
                                        pattern: /[a-z]+/,
                                        message: 'Must contain at least 1 lowercase character\n\r',
                                    },
                                    {
                                        pattern: /[!#$@%\-_=+<>\'"`£^&¬\.*\(\)\[\]\?:;\/,{}]/,
                                        message: 'Must contain at least 1 special character',
                                    },
                                ],
                            })(<Input placeholder="Confirm your new password" type="password" size="large" />)}
                        </FormItem>
                        <div className="text-center mt-5">
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>

                        </div>
                        <div>
                            <Link to="/">
                                <span className="link-green btn-empty underlined center-align">
                                    Cancel
                                </span>
                            </Link>
                        </div>
                    </Form>
                    <div className="d-flex dir-column align-center text-center">
                        <h3 className="login__heading">New to Fleetclear?</h3>
                        <a href="https://fleetclear.com/contact-us/">
                            <span className="login__contact-us">
                                <span className="login__contact-us-icon">
                                    <Icon name="envelope" />
                                </span>
                                <span>Contact us</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </Spin>)
    );
}
