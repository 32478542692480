/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';
import {
    isEmpty,
} from 'underscore';
import { notification } from 'antd';

import moment from 'moment';
import DOM from './mileageReport';

import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as globalActions from '../../../../core/global/globalActions';

class MileageReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            filterSidebar: true,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, dataEventToReportParams } = this.props;
        actions.resetReportsRequest();
        const params = {
            asset_or_driver: 'assets',
            frequency: '1',
            ...dataEventToReportParams,
            page: 1,
            date_to: moment(),
        };

        if (dataEventToReportParams !== null) {
            if (dataEventToReportParams.asset_id) {
                params.asset_info = [dataEventToReportParams.asset_id];
            }

            if (dataEventToReportParams.driver_id) {
                params.driver = [dataEventToReportParams.driver_id];
            }
            params.date_to = params.date_to.format('YYYY-MM-DD');
            actions.getMileageReportRequest(params);
            actions.setDataEventToReportParams(null);

            if (dataEventToReportParams.driver_id) {
                params.driver = [parseInt(dataEventToReportParams.driver_id)];
            }
            this.setState({ filters: params, filterSidebar: false });
        } else {
            this.setState({ filters: params, filterSidebar: false });
            this.onEventsTabFilterChange(params);
        }
    }

    onEventsTabFilterChange = (values) => {
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format('YYYY-MM-DD');
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }
        if (filters.asset_id) {
            filters.asset_info = filters.asset_id;
        }

        filters.page = 1;
        this.setState({ filters });
        const { actions } = this.props;
        const params = { ...filters };
        actions.getMileageReportRequest(params);
    };

    exportCsv() {
        const { mileageReport } = this.props;
        const { filters } = this.state;
        if (isEmpty(mileageReport)) {
            notification.warning({ message: 'Warning', description: 'There is no data to export!' });
            return false;
        }

        let reportTitle = 'Drivers';
        if (filters && filters.asset_or_driver) {
            if (filters.asset_or_driver === 'assets') {
                reportTitle = 'Assets';
                mileageReport.map((item, key) => {
                    if (item.name[1]) {
                        mileageReport[key].name = item.name[1];
                    }
                });
            }
        }

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: `${reportTitle} Mileage Report CSV`,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: `${reportTitle} Mileage Report`,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(mileageReport);

        return true;
    }

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

MileageReport.propTypes = {
    actions: PropTypes.object.isRequired,
    mileageReport: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        mileageReport: state.report.mileageReport,
        events: state.events.companyEvents,
        filterSidebar: state.filterSidebar,
        dataEventToReportParams: state.global.dataEventToReportParams,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MileageReport);
