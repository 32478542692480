import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './divisionsView';

import * as divisionActions from '../../core/division/divisionActions';

class DivisionsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            divisionAssetsSearchParams: {},
            divisionAssetsOrderParams: {},
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, divisionId } = this.props;
        // console.log({ actions });
        actions.getDivisionDetailsRequest({ division_id: divisionId });
        actions.getDivisionAssetsGridRequest({ division_id: divisionId });
        actions.getOtherAssetsRequest({ division_id: divisionId });
    }

    gridChange = (pagination, filters, sorter) => {
        const { actions, divisionAssetsPagination, divisionId } = this.props;
        const { divisionAssetsSearchParams, divisionAssetsOrderParams } = this.state;
        if (pagination && pagination.current === divisionAssetsPagination.currentPage) {
            divisionAssetsOrderParams[sorter.field] = sorter.order ? sorter.order : false;
            this.setState({ divisionAssetsOrderParams });
            actions.getDivisionAssetsGridRequest({
                division_id: divisionId,
                page: divisionAssetsPagination.currentPage,
                divisionAssetsSearchParams,
                divisionAssetsOrderParams,
            });
        }
    };

    paginationChange = (page) => {
        const { actions, divisionAssetsPagination, divisionId } = this.props;
        const { divisionAssetsSearchParams, divisionAssetsOrderParams } = this.state;
        if (page !== divisionAssetsPagination.currentPage) {
            actions.getDivisionAssetsGridRequest({
                division_id: divisionId,
                page,
                divisionAssetsSearchParams,
                divisionAssetsOrderParams,
            });
        }
    };

    removeAsset(asset_id) {
        // console.log({ asset_id });
        const { actions, divisionId } = this.props;
        actions.unassignDivisionAssetRequest({ division_id: divisionId, asset_id });
    }

    assignAsset(asset_id) {
        // console.log({ asset_id });
        const { actions, divisionId } = this.props;
        actions.assignDivisionAssetRequest({ division_id: divisionId, asset_id });
    }

    render() {
        return this.view();
    }
}

DivisionsView.propTypes = {
    actions: PropTypes.object.isRequired,
    divisionAssetsPagination: PropTypes.object.isRequired,
    divisionId: PropTypes.number.isRequired,
    divisionDetail: PropTypes.object.isRequired,
    otherAssets: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
    const divisionId = parseInt(ownProps.match.params.divisionId, 10);
    return {
        ...ownProps,
        divisionDetail: state.division.divisionDetail,
        divisionAssets: state.division.divisionAssets,
        divisionAssetsPagination: state.division.divisionAssetsPagination,
        divisionAssetsIsFetching: state.division.isFetching,
        otherAssets: state.division.allAssets,
        divisionId,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...divisionActions,
            },
            dispatch,
        ),
    };
}
export const DivisionsViewTest = DivisionsView;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DivisionsView);
