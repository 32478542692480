import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import lockr from 'lockr';
import DOM from './eventShareCustomView';
import * as eventsActions from '../../core/events/eventsActions';
import * as globalActions from '../../core/global/globalActions';

const { SHARD_KEY } = require('../../core/constants').default;

class EventShareCustomView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedEvent: {},
            report: {},

        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, match } = this.props;
        let { shardId } = this.props;
        const { code } = match.params;
        if (code && code.contains('_')) {
            shardId = code.split('_')[0];
            lockr.set(SHARD_KEY, shardId);
            actions.setShardId(shardId);
        }
        actions.verifyReportCodeRequest({
            code,
            shardId: shardId || '1',
        });
    }

    render() {
        return this.view();
    }
}

EventShareCustomView.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        report: state.events.report,
        selectedEvent: state.events.selectedEvent,
        isFetching: state.events.isFetching,
        shardId: state.global.shardId,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
                ...globalActions,
            },
            dispatch,
        ),
    };
}
export const EventShareViewTest = EventShareCustomView;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventShareCustomView);
