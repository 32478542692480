/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    Menu, Dropdown, Button, Tooltip as AntTooltip,
} from 'antd';
import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'underscore';

import Icon from '../../../elements/Icon';
import './chartHeader.scss';

export default function () {
    const {
        rightMenuItems, rightMenuOnClick, tooltipText, hideTimeframe
    } = this.props;
    const { timeframe } = this.state;

    const timeframesMenuGetter = (currentTimeframe) => {
        const { type } = this.props;

        if (type === 'vor') {
            return (
                <Menu>
                    <Menu.Item
                        disabled={currentTimeframe === '1 day'}
                        key="0"
                        onClick={() => this.onTimeframeChange('1 day')}>
                        1 day
                    </Menu.Item>
                    <Menu.Item
                        disabled={currentTimeframe === '3 days'}
                        key="1"
                        onClick={() => this.onTimeframeChange('3 days')}>
                        3 days
                    </Menu.Item>
                    <Menu.Item
                        disabled={currentTimeframe === '7 days'}
                        key="3"
                        onClick={() => this.onTimeframeChange('7 days')}>
                        7 days
                    </Menu.Item>
                    <Menu.Item
                        disabled={currentTimeframe === 'current month'}
                        key="4"
                        onClick={() => this.onTimeframeChange('current month')}>
                        current month
                    </Menu.Item>
                </Menu>
            )
        } else {
            return (
                <Menu>
                    <Menu.Item
                        disabled={currentTimeframe === 'hourly'}
                        key="0"
                        onClick={() => this.onTimeframeChange('hourly')}>
                        Hourly
                    </Menu.Item>
                    <Menu.Item
                        disabled={currentTimeframe === 'daily'}
                        key="1"
                        onClick={() => this.onTimeframeChange('daily')}>
                        Daily
                    </Menu.Item>
                    <Menu.Item
                        disabled={currentTimeframe === 'weekly'}
                        key="2"
                        onClick={() => this.onTimeframeChange('weekly')}>
                        Weekly
                    </Menu.Item>
                    <Menu.Item
                        disabled={currentTimeframe === 'monthly'}
                        key="3"
                        onClick={() => this.onTimeframeChange('monthly')}>
                        Monthly
                    </Menu.Item>
                    <Menu.Item
                        disabled={currentTimeframe === 'yearly'}
                        key="4"
                        onClick={() => this.onTimeframeChange('yearly')}>
                        Yearly
                    </Menu.Item>
                </Menu>
            )
        }
    };

    const menu = (
        <Menu>
            {
                !isEmpty(rightMenuItems)
                    ? rightMenuItems.map((a) => (
                        <Menu.Item key={`right-menu-${a}`} onClick={() => rightMenuOnClick(a)}>
                            {a}
                        </Menu.Item>
                    ))
                    : null
            }
        </Menu>
    );

    return (
        <div className="new-chart-widget-header ">
            {!isEmpty(tooltipText) ? (
                <>
                    <AntTooltip title={tooltipText} placement="rightTop">
                        <InfoCircleOutlined />
                    </AntTooltip>
                    <div className='widget-header-title'>{tooltipText}</div>
                </>
            )
                : null }

            {hideTimeframe !== true ? (
                <Dropdown overlay={timeframesMenuGetter(timeframe)} placement="topRight" trigger={['click']} className="custom-right-menu">
                    <Button className="btn btn-empty timeframe-switcher" style={{ right: 15 }}><CaretDownOutlined /> {timeframe}</Button>
                </Dropdown>
            )
                : null}

            {!isEmpty(rightMenuItems) ? (
                <Dropdown overlay={menu} trigger={['click']} className="custom-right-menu">
                    <Button className="btn btn-empty">
                        <Icon name="more" />
                    </Button>
                </Dropdown>
            )
                : null}
        </div>
    );
}
