import React, { Component } from 'react';
import { RightOutlined } from '@ant-design/icons';
import {
    Col, Input, Row, Spin, notification, Switch, 
} from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';
import PropTypes from 'prop-types';
import Icon from '../../../components/elements/Icon';
import { getDistanceInMiles } from '../../../core/utils/api';
import * as deviceActions from '../../../core/device/deviceActions';
import * as assetActions from '../../../core/asset/assetActions';
import * as globalActions from '../../../core/global/globalActions';
import AssetsSearchTree from '../../../components/AssetsSearchTree';

const { Search } = Input;
const { MAP_SLIDER_INTERVAL } = require('../../../core/constants').default;

class AssetsSearchSidebar extends Component {
    constructor(props) {
        super(props);
        //  this.searchBoxsearchBox = React.createRef();
        this.liveInterval = null;
        this.filteredAssets = [];
        const { checkedAssets } = this.props;
        this.state = {
            clearSelection: checkedAssets.length === 0,
            checkedAssets: props.checkedAssets,
            assetSearchTerm: '',
            typingTimeout: 0,
            mapLiveAssetsLocation: [],
            assetList: [],
        };
    }

    componentDidMount() {
        const { actions } = this.props;

        if (this.liveInterval) clearInterval(this.liveInterval);
        this.makeAssetSearchRequest();
        actions.getAllAssetOptionsRequest();
    }

    componentDidUpdate(prevProps) {
        const {
            assetList, checkedAssets, mapAssets, mapLiveAssets, isMapLive,
        } = this.props;

        if (Array.isArray(assetList) && assetList.length && assetList !== prevProps.assetList) {
            this.setState({ assetList });
        }

        if (checkedAssets !== prevProps.checkedAssets) {
            this.setState({ checkedAssets });
            if (checkedAssets.length === 0) {
                this.setState({ clearSelection: true });
            } else if (checkedAssets && checkedAssets[0] == '999' && isMapLive && mapLiveAssets.length > 0) {
                this.setState({ clearSelection: false });
            }
        }

        if (mapLiveAssets.length !== prevProps.mapLiveAssets.length) {
            if (isMapLive && checkedAssets && checkedAssets[0] == '999') {
                this.setState({ clearSelection: false });
            }
        }

        if (mapAssets.length !== prevProps.mapAssets.length) {
            if (!isMapLive && checkedAssets && checkedAssets[0] == '999') {
                this.setState({ clearSelection: false });
            }
        }
    }

    componentWillUnmount() {
        if (this.liveInterval) {
            clearInterval(this.liveInterval);
        }
    }

    assetSearch = (assets, clearSelectedAsset = false) => {
        const { updateMapFromSearchTree } = this.props;

        this.setState({ checkedAssets: assets });
        updateMapFromSearchTree(assets, clearSelectedAsset);
    };

    searchBoxOnChange = (e) => {
        const { typingTimeout } = this.state;
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        this.setState({
            assetSearchTerm: e.target.value,
            // typingTimeout: setTimeout(() => {
            //     this.makeAssetSearchRequest();
            // }, 1500),
        });
    };

    setClearSelection = () => {
        const { clearSelection } = this.state;
        const updatedState = { clearSelection: !clearSelection };
        if (updatedState.clearSelection == true) {
            updatedState.checkedAssets = [];
        }
        this.setState(updatedState);
        const { assets } = this.getAssets();
    };

    makeAssetSearchRequest = (page = 1) => {
        const {
            actions, userMapCenter, isMapLive, selectedDate,
        } = this.props;
        const { assetSearchTerm } = this.state;
        actions.mapAssetSearchRequest({
            search_term: assetSearchTerm,
            page,
            latitude: userMapCenter.lat,
            longitude: userMapCenter.lng,
            date: selectedDate,
            // is_live: isMapLive,
        });
    };

    renderItem = (index, key) => {
        const {
            markerOnClick, googleMapAccess, userMapCenter, selectedDate, selectedSliderValue,
        } = this.props;
        const searchedAsset = this.filteredAssets[index];
        return (
            <Row
                key={`asset-row-${key + 1}`}
                className={`dataitem ${searchedAsset.time === '' ? 'disabled' : ''}`}
                onClick={() => {
                    if (searchedAsset.time !== '') {
                        // Convert slider value to actual hours and minutes
                        const hoursAndMinutesCombined = ((selectedSliderValue * MAP_SLIDER_INTERVAL) / 60);
                        let hours = Math.floor(hoursAndMinutesCombined);
                        if (hours <= 9) {
                            hours = (`0${hours}`).slice(-2);
                        }
                        let minutes = Math.floor((hoursAndMinutesCombined % 1) * 60);
                        if (minutes <= 9) {
                            minutes = (`0${minutes}`).slice(-2);
                        }
                        const hoursAndMinutes = `${hours}:${minutes}:00`;
                        const marker = {};
                        marker.imei = searchedAsset.imei;
                        marker.lat = searchedAsset.lat;
                        marker.lng = searchedAsset.lng;
                        marker.dateTime = `${selectedDate} ${hoursAndMinutes}` || searchedAsset.time;
                        marker.id = 0;
                        markerOnClick(marker);
                        if (googleMapAccess && googleMapAccess.current && googleMapAccess.current._googleMap) {
                            googleMapAccess.current.setState({ showInfoBox: true });
                            const position = { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) };
                            const map = googleMapAccess.current._googleMap.map_;
                            map.setCenter(position);
                        }
                        return null;
                    }
                    notification.info({ message: 'Information', description: `Unable to find any record to "${searchedAsset.name || '[not-set]'}".` });
                }}> {/* ${item.active ? 'is-active' : ''} */}
                <Col span={2}>
                    <span className="asset-status test">
                        {searchedAsset.status == '1' ? <span className="indicator-activity" /> : <span className="indicator-activity red" />}
                    </span>
                </Col>
                <Col span={20}>
                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                        {searchedAsset.name || '[not-set]'}
                        {' '}
                        <i style={{
                            fontWeight: 'normal', fontSize: '12px', float: 'right', color: 'rgba(111,111,111,0.63)',
                        }}>
                            {searchedAsset.lat && searchedAsset.lng
                                ? `(${getDistanceInMiles(userMapCenter, { lat: searchedAsset.lat, lng: searchedAsset.lng })} mi)`
                                : 'N/A'}
                        </i>
                    </div>
                    <div style={{ fontWeight: 'normal', fontSize: '12px', color: 'rgba(111,111,111,0.63)' }}>
                        {searchedAsset.registration} - {searchedAsset.make} {searchedAsset.model}
                    </div>
                </Col>
                <Col span={2}>
                    <RightOutlined style={{ float: 'right' }} />
                </Col>
            </Row>
        );
    };

    getAssets = () => {
        const {
            mapAssets, isMapLive, mapLiveAssets, mapAssetsPagination,
        } = this.props;
        const { assetList } = this.state;

        let assets = mapAssets;
        const totalAssets = assetList.length;
        let onlineAssets = (mapAssetsPagination && mapAssetsPagination.onlineAssets) || 0;
        onlineAssets = (assets.filter((asset) => moment(asset.time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'))).length;

        let onlineMapAssets = [];
        if (isMapLive) {
            assets = mapLiveAssets;
            onlineAssets = (mapLiveAssets.filter((el) => parseInt(el.device_status, 10) === 1).length) || 0;
            onlineMapAssets = mapLiveAssets.filter((el) => parseInt(el.device_status, 10) === 1);
        } else {
            onlineMapAssets = mapAssets.filter((el) => parseInt(el.device_status, 10) === 1);
        }

        return {
            assets, totalAssets, onlineAssets, onlineMapAssets,
        };
    };

    render() {
        const {
            isFetchingBasicAssetList, mapAssets, userMapCenter, mapAssetsPagination,
            centreMap, googleMapAccess, backLinkClick, mapLiveAssets, assetOnMouseOver, assetOnMouseOut,
            isMapLive, selectedSliderValue, selectedAsset,
        } = this.props;
        const { checkedAssets, clearSelection } = this.state;

        const {
            assets, totalAssets, onlineAssets, onlineMapAssets,
        } = this.getAssets();
        const checkedAssetsWithoutDivisions = checkedAssets
            ? checkedAssets.filter((ca) => ca.indexOf('division') < 0)
            : checkedAssets;
        const actualCheckedAssets = checkedAssets && checkedAssets.length && checkedAssets[0] === '999'
            ? assets.length
            : checkedAssetsWithoutDivisions.length;

        return (
            <div style={{ width: 336 }}>
                <div className="filter__sidebar">
                    <div className="filter__sidebar-inner" style={{ height: '86.5vh' }}>
                        <div className="filter__sidebar-data">
                            <AssetsSearchTree
                                leafOnSelect={(e) => { centreMap(e, true); }}
                                selectedSliderValue={selectedSliderValue}
                                clearSelection={clearSelection}
                                assetSearch={this.assetSearch}
                                assets={assets}
                                checkedAssets={checkedAssets}
                                selectedAsset={selectedAsset}
                                assetOnMouseOver={assetOnMouseOver}
                                assetOnMouseOut={assetOnMouseOut}
                                mapAssets={onlineMapAssets}
                                isMapLive={isMapLive}
                                backLinkClick={backLinkClick}
                                actualCheckedAssets={actualCheckedAssets}
                                totalAssets={totalAssets} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AssetsSearchSidebar.defaultProps = {
    isMapLive: true,
    selectedDate: moment().format('YYYY-MM-DD'),
    updateMapFromSearchTree: () => {},
    checkedAssets: ['select-all'],
};

AssetsSearchSidebar.propTypes = {
    isMapLive: PropTypes.bool.isRequired,
    selectedDate: PropTypes.string.isRequired,
    updateMapFromSearchTree: PropTypes.func.isRequired,
    checkedAssets: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.device.isFetching,
        mapAssets: state.device.mapAssets,
        mapAssetsPagination: state.device.mapAssetsPagination,
        isFetchingBasicAssetList: state.asset.isFetchingBasicAssetList,
        userMapCenter: state.global.userMapCenter,
        selectedSliderValue: state.global.selectedSliderValue,
        shardId: state.global.shardId,
        assetList: state.asset.allOptions,
        company: state.user.userCompany,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...deviceActions,
                ...assetActions,
                ...globalActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetsSearchSidebar);
