import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Button, Modal, Spin } from 'antd';
import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';

import Icon from '../elements/Icon';

import './loginForm.css';

const FormItem = Form.Item;

export default function () {
    const { form, isFetching } = this.props;
    const { getFieldDecorator } = form;
    return (
        (<Spin spinning={isFetching}>
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <div className="d-flex dir-column align-center text-center">
                        <img 
                            src={srcFleetclearLogo} 
                            className="login__logo-fleet" 
                            alt="Fleetclear" 
                            style={{ width: '80%' }}
                        />
                        <h1 className="login__heading" />
                    </div>
                    <Form onSubmit={this.handleSubmit} layout="vertical">
                        <FormItem>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid username',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ],
                            })(<Input placeholder="username / email" />)}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ],
                            })(<Input type="password" placeholder="Password" />)}
                        </FormItem>
                        <div style={{ marginTop: '-10px' }}>
                            <a
                                href='#'
                                onClick={() => this.setModalForgetVisible(true)}
                            >
                                Forgotten password?
                            </a>
                        </div>
                        <Button 
                            style={{ width: '70%' }} 
                            htmlType="submit" 
                            type='primary'
                        >
                            Login
                        </Button>
                    </Form>
                    <div className="d-flex dir-column align-center text-center">
                        <h3 className="login__heading">New to Fleetclear?</h3>
                        <a href="https://fleetclear.com/contact-us/">
                            <span className="login__contact-us">
                                <span className="login__contact-us-icon">
                                    <Icon name="envelope" />
                                </span>
                                <a>Contact us</a>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <Modal
                centered
                width={540}
                footer={null}
                open={this.state.modalForgetVisible}
                onOk={() => this.setModalForgetVisible(false)}
                onCancel={() => this.setModalForgetVisible(false)}>
                <h4 className="h-3 text-center">Reset Password</h4>
                <div className="form-wrap">
                    <Form onSubmit={this.handleForgotsubmit} layout="vertical">
                        <FormItem label="Email">
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid username',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ],
                            })(<Input placeholder="Your Registered Email" />)}
                        </FormItem>
                        <Button type="primary" htmlType="submit" className="wide center-align">
                            Send Email
                        </Button>
                    </Form>
                </div>
            </Modal>
        </Spin>)
    );
}
