import React from 'react';
import Icon from '@ant-design/icons';
import { getTextWidth } from '../../../../../core/utils/functions';
import AssetArrowSVG from '../../../assets/AssetArrowSVG';

import './assetMarkerContent.scss';

export enum AssetMarkerStatus {
    Online = 'online',
    Idle = 'idle',
    Offline = 'offline',
}

interface AssetMarkerContentProps {
    divisionColor?: string;
    status?: AssetMarkerStatus;
    angle?: number;
    label?: string;
}

const getArrowColor = (status: AssetMarkerStatus): string => {
    switch (status) {
        case AssetMarkerStatus.Online:
            return 'green';
        case AssetMarkerStatus.Idle:
            return 'yellow';
        case AssetMarkerStatus.Offline:
            return 'red';
        default:
            return 'red';
    }
}

const AssetMarkerContent: React.FC<AssetMarkerContentProps> = ({
    divisionColor = '#fff',
    status = AssetMarkerStatus.Offline,
    angle = 0,
    label = '',
}) => {
    const assetNameWidth = label ? getTextWidth(label, 'bold 14px Inter, sans-serif') + 20 : 0;
    
    return (
        <div 
            className='asset-marker-content'
            style={{ width: label ? `${assetNameWidth + 24}px` : '48px' }}
        >
            <div 
                className='asset-marker'
                style={{ 
                    width: label ? `${assetNameWidth + 24}px` : '24px',
                    marginLeft: label ? `${(assetNameWidth + 24) / 2}px` : '24px',
                    backgroundColor: divisionColor,
                }}
            >
                <div className='asset-marker-arrow-container'>
                    <div className='asset-marker-arrow-background'>
                        <Icon 
                            component={AssetArrowSVG} 
                            className='asset-marker-arrow'
                            style={{ 
                                color: getArrowColor(status),
                                WebkitTransform: `rotate(${angle}deg)`,
                                transform: `rotate(${angle}deg)`,
                            }}
                        />
                    </div>
                </div>
                {label ? (
                    <div 
                        className="asset-marker-label" 
                        style={{ width: assetNameWidth }}
                    >
                        {label}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default AssetMarkerContent;