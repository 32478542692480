import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import DOM from './notMovedReportPage';
import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as globalActions from '../../../../core/global/globalActions';

class NotMovedReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Set the from and to dates to the current date
            filters: {
                date_to: moment(),
            },
            filterSidebar: true,
        };

        this.view = DOM;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.resetReportsRequest();

        this.onEventsTabFilterChange({
            date_to: moment(),
            asset_or_driver: 'assets',
            include_weekends: 'true',
            number_of_days: 5,
            page: 1,
        });
    }

    onEventsTabFilterChange = (values) => {
        const filters = values;
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }

        filters.include_weekends = values.include_weekends === 'true';

        filters.page = 1;
        this.setState({ filters });
        const { actions } = this.props;
        const params = { ...filters };

        actions.getNotMovedReportRequest(params);
    };

    render() {
        return this.view();
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        filterSidebar: state.filterSidebar,
        notMovedReport: state.report.notMovedReport,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotMovedReport);
