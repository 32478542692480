import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import { DatePicker, Input, Button, Row, Col } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isoStandardDateTimeFormat } from '../../../core/utils/dateUtils';

dayjs.extend(customParseFormat);

class assetJourneyHistoryFilterForm extends Component {
    constructor(props) {
        super(props);
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const { form, telematicsRetentionDays } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Row>
                    <Col span={12}>
                        <Form.Item label="Date from">
                            {getFieldDecorator('date_from', { initialValue: dayjs() })(
                                <DatePicker 
                                    format="DD/MM/YYYY" 
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    allowClear={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Date to">
                            {getFieldDecorator('date_to', { initialValue: dayjs() })(
                                <DatePicker 
                                    format="DD/MM/YYYY" 
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    allowClear={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Current Location">
                    {getFieldDecorator('current_location', {

                    })(
                        <Input placeholder="Current location" />,
                    )}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

const assetJourneyHistoryFilter = Form.create({ name: 'event_filter_form' })(assetJourneyHistoryFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(assetJourneyHistoryFilter);
