/* global: window */
import { Record } from 'immutable';
import { reject, findIndex } from 'underscore';
import { getTodayInServerFormat, getTodayStartInServerFormat } from '../utils/dateUtils';
// import { ExportToCsv } from 'export-to-csv';
import { addAsset, removeAsset } from './helpers';

const {
    ASSET_SEARCH_REQUEST,
    ASSET_SEARCH_SUCCESS,
    ASSET_SEARCH_FAILURE,

    FILTER_ASSETS_REQUEST,
    FILTER_ASSETS_DIVISION_REQUEST,
    FILTER_ASSETS_SUCCESS,
    FILTER_ASSETS_FAILURE,

    CREATE_ASSET_REQUEST,
    CREATE_ASSET_SUCCESS,
    CREATE_ASSET_FAILURE,

    GET_ASSET_INFO_REQUEST,
    GET_ASSET_INFO_SUCCESS,
    GET_ASSET_INFO_FAILURE,

    DELETE_ASSET_REQUEST,
    DELETE_ASSET_SUCCESS,
    DELETE_ASSET_FAILURE,

    GET_ASSET_INFO_BY_IMEI_AND_DATE_REQUEST,
    GET_ASSET_INFO_BY_IMEI_AND_DATE_SUCCESS,
    GET_ASSET_INFO_BY_IMEI_AND_DATE_FAILURE,

    GET_ASSET_MAP_ALERT_HISTORY_REQUEST,
    GET_ASSET_MAP_ALERT_HISTORY_SUCCESS,
    GET_ASSET_MAP_ALERT_HISTORY_FAILURE,

    GET_EVENT_TYPES_REQUEST,
    GET_EVENT_TYPES_SUCCESS,
    GET_EVENT_TYPES_FAILURE,

    GET_ASSET_JOURNEY_REQUEST,
    GET_ASSET_JOURNEY_SUCCESS,
    GET_ASSET_JOURNEY_FAILURE,

    RESET_ASSETS_REQUEST,
    RESET_ASSET_IS_FETCHINGS,

    GET_ASSET_LIST_REQUEST,
    GET_ASSET_LIST_SUCCESS,
    GET_ASSET_LIST_FAILURE,

    GET_BASIC_ASSET_LIST_REQUEST,
    GET_BASIC_ASSET_LIST_SUCCESS,
    GET_BASIC_ASSET_LIST_FAILURE,

    GET_ASSET_EVENT_HISTORY_REQUEST,
    GET_ASSET_EVENT_HISTORY_SUCCESS,
    GET_ASSET_EVENT_HISTORY_FAILURE,

    GET_ASSET_SERVICE_ALERTS_REQUEST,
    GET_ASSET_SERVICE_ALERTS_SUCCESS,
    GET_ASSET_SERVICE_ALERTS_FAILURE,

    CREATE_SERVICE_ALERT_REQUEST,
    CREATE_SERVICE_ALERT_SUCCESS,
    CREATE_SERVICE_ALERT_FAILURE,

    UPLOAD_ASSET_IMAGE_REQUEST,
    UPLOAD_ASSET_IMAGE_SUCCESS,
    UPLOAD_ASSET_IMAGE_FAILURE,

    UPLOAD_NEW_ASSET_IMAGE_REQUEST,
    UPLOAD_NEW_ASSET_IMAGE_SUCCESS,
    UPLOAD_NEW_ASSET_IMAGE_FAILURE,

    GET_ASSET_LIVE_VIDEO_URL_REQUEST,
    GET_ASSET_LIVE_VIDEO_URL_SUCCESS,
    GET_ASSET_LIVE_VIDEO_URL_FAILURE,

    QUEUE_BY_DEVICE_ID_REQUEST,
    QUEUE_BY_DEVICE_ID_SUCCESS,
    QUEUE_BY_DEVICE_ID_FAILURE,

    GET_ASSET_HISTORIC_VIDEO_REQUEST,
    GET_ASSET_HISTORIC_VIDEO_SUCCESS,
    GET_ASSET_HISTORIC_VIDEO_FAILURE,

    UPDATE_ASSET_REQUEST,
    UPDATE_ASSET_SUCCESS,
    UPDATE_ASSET_FAILURE,

    GET_DIVISIONS_REQUEST,
    GET_DIVISIONS_SUCCESS,
    GET_DIVISIONS_FAILURE,

    GET_ALL_ASSET_OPTIONS_REQUEST,
    GET_ALL_ASSET_OPTIONS_SUCCESS,
    GET_ALL_ASSET_OPTIONS_FAILURE,

    GET_ASSET_OPTIONS_REQUEST,
    GET_ASSET_OPTIONS_SUCCESS,
    GET_ASSET_OPTIONS_FAILURE,

    GET_ASSET_OPTIONS_STRICT_REQUEST,
    GET_ASSET_OPTIONS_STRICT_SUCCESS,
    GET_ASSET_OPTIONS_STRICT_FAILURE,

    GET_DIVISION_LIST_REQUEST,
    GET_DIVISION_LIST_SUCCESS,
    GET_DIVISION_LIST_FAILURE,

    DELETE_SERVICE_ALERT_REQUEST,
    DELETE_SERVICE_ALERT_SUCCESS,
    DELETE_SERVICE_ALERT_FAILURE,

    GET_VEHICLE_TYPES_REQUEST,
    GET_VEHICLE_TYPES_SUCCESS,
    GET_VEHICLE_TYPES_FAILURE,

    SAVE_ASSET_SEARCH_PARAMS_REQUEST,
    SAVE_ASSET_ORDER_PARAMS_REQUEST,
    SAVE_ASSET_SEARCH_ID_REQUEST,

    TOGGLE_ASSET_SELECTED,
    CLEAR_SELECTED_ASSETS,

    UPDATE_ASSET_JOURNEY_LOCATION_REQUEST,
    UPDATE_ASSET_JOURNEY_LOCATION_SUCCESS,
    UPDATE_ASSET_JOURNEY_LOCATION_FAILURE,

    GET_VEHICLE_MAKES_REQUEST,
    GET_VEHICLE_MAKES_SUCCESS,
    GET_VEHICLE_MAKES_FAILURE,

    GET_VEHICLE_MAKE_MODELS_REQUEST,
    GET_VEHICLE_MAKE_MODELS_SUCCESS,
    GET_VEHICLE_MAKE_MODELS_FAILURE,

    DELETE_ASSET_DATA_REQUEST,
    DELETE_ASSET_DATA_SUCCESS,
    DELETE_ASSET_DATA_FAILURE,

    GET_CSV_ASSET_RECORDS_BY_DAY_REQUEST,
    GET_CSV_ASSET_RECORDS_BY_DAY_SUCCESS,
    GET_CSV_ASSET_RECORDS_BY_DAY_FAILURE,

    SET_ASSET_JOURNEY_FILTERS,
} = require('./assetActions').constants;

const {
    LOGOUT_REQUEST,
} = require('../user/userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: [],
    currentTabKey: 1,
    assetList: [],
    basicAssetList: [],
    isFetchingBasicAssetList: false,
    assets: [],
    allOptions: [],
    options: [],
    optionsStrict: [],
    divisions: [],
    divisionList: [],
    selectedAssets: [],
    pagination: {
        currentPage: 1,
        pageCount: 0,
        perPage: 0,
        totalCount: 0,
    },
    singleAsset: {},
    assetInfo: {},
    assetMapAlertHistory: [],
    assetMapAlertHistoryPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    event_types: [],
    assetJourney: [],
    assetJourneyPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    assetJourneyFilters: {
        dateRange: [
            getTodayStartInServerFormat(),
            getTodayInServerFormat(),
        ],
    },
    assetEventHistory: [],
    assetEventHistoryPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    assetVideoHistory: [],
    assetVideoHistoryPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 16,
    },
    serviceAlerts: [],
    serviceAlertsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    assetLiveVideos: [],
    vehicleTypes: [],
    vehicleMakes: [],
    vehicleModels: [],
    assetSearchParams: {},
    assetSearchIdParams: {},
    assetOrderParams: {},
    newAssetImage: 0,
    isExportingCsv: false,
});

const assetInitialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function assetReducer(state = assetInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) return assetInitialState.mergeDeep(state);
    const { isFetching } = state;
    const tempType = type.replace('_REQUEST', '').replace('_SUCCESS', '').replace('_FAILURE', '');
    const newObj = {};
    newObj[tempType] = true;
    switch (type) {
    case ASSET_SEARCH_REQUEST:
    case FILTER_ASSETS_REQUEST:
    case CREATE_ASSET_REQUEST:
    case GET_ASSET_INFO_REQUEST:
    case DELETE_ASSET_REQUEST:
    case GET_ASSET_INFO_BY_IMEI_AND_DATE_REQUEST:
    case GET_ASSET_MAP_ALERT_HISTORY_REQUEST:
    case GET_ASSET_JOURNEY_REQUEST:
    case GET_ASSET_LIST_REQUEST:
    case GET_ASSET_EVENT_HISTORY_REQUEST:
    case GET_ASSET_HISTORIC_VIDEO_REQUEST:
    case GET_ASSET_SERVICE_ALERTS_REQUEST:
    case CREATE_SERVICE_ALERT_REQUEST:
    case UPLOAD_ASSET_IMAGE_REQUEST:
    case UPLOAD_NEW_ASSET_IMAGE_REQUEST:
    case GET_ASSET_LIVE_VIDEO_URL_REQUEST:
    case QUEUE_BY_DEVICE_ID_REQUEST:
    case UPDATE_ASSET_REQUEST:
    case GET_DIVISIONS_REQUEST:
    case GET_ALL_ASSET_OPTIONS_REQUEST:
    case GET_ASSET_OPTIONS_REQUEST:
    case GET_ASSET_OPTIONS_STRICT_REQUEST:
    case GET_DIVISION_LIST_REQUEST:
    case DELETE_SERVICE_ALERT_REQUEST:
    case GET_VEHICLE_TYPES_REQUEST:
    case UPDATE_ASSET_JOURNEY_LOCATION_REQUEST:
    case GET_VEHICLE_MAKES_REQUEST:
    case GET_VEHICLE_MAKE_MODELS_REQUEST:
    case DELETE_ASSET_DATA_REQUEST: {
        isFetching.push(newObj);
        const newArray = [...isFetching];
        return state.set('isFetching', newArray)
            .set('error', null);
    }

    case GET_BASIC_ASSET_LIST_REQUEST:
        return state.set('isFetchingBasicAssetList', true)
            .set('error', null);

    case GET_CSV_ASSET_RECORDS_BY_DAY_REQUEST:
        return state.set('isExportingCsv', true);

    case FILTER_ASSETS_DIVISION_REQUEST:
        return state.set({ currentTabKey: '1' })
            .set('assetSearchParams', payload)
            .set('error', null);

    case LOGOUT_REQUEST:
        return state.set('assetSearchParams', {})
            .set('assetOrderParams', {});

    case ASSET_SEARCH_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('assets', payload.data);

    case SAVE_ASSET_SEARCH_PARAMS_REQUEST: {
        return state.set('assetSearchParams', payload);
    }

    case SAVE_ASSET_SEARCH_ID_REQUEST: {
        return state.set('assetSearchIdParams', payload);
    }

    case SAVE_ASSET_ORDER_PARAMS_REQUEST: {
        return state.set('assetOrderParams', payload);
    }

    case DELETE_ASSET_DATA_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true)).set('singleAsset', {});

    case GET_VEHICLE_MAKES_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true)).set('vehicleMakes', payload.data);

    case GET_VEHICLE_MAKE_MODELS_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true)).set('vehicleModels', payload.data);

    case FILTER_ASSETS_SUCCESS: {
        const { pagination } = state;
        if (payload.headers) {
            if (payload.headers['x-pagination-current-page']) {
                pagination.currentPage = parseInt(payload.headers['x-pagination-current-page'], 10);
            }
            if (payload.headers['x-pagination-page-count']) {
                pagination.pageCount = parseInt(payload.headers['x-pagination-page-count'], 10);
            }
            if (payload.headers['x-pagination-per-page']) {
                pagination.perPage = parseInt(payload.headers['x-pagination-per-page'], 10);
            }
            if (payload.headers['x-pagination-total-count']) {
                pagination.totalCount = parseInt(payload.headers['x-pagination-total-count'], 10);
            }
        }

        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('pagination', pagination)
            .set('assets', payload.data);
    }

    case RESET_ASSETS_REQUEST:
        // isFetching.push(newObj);
        return state.set('isFetching', isFetching)
            .set('assets', []);
            
    case RESET_ASSET_IS_FETCHINGS:
        return state.set('isFetching', []);

    case FILTER_ASSETS_FAILURE: // @Audrius - Is this correct?
    case CREATE_ASSET_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('singleAsset', payload.data);

    case GET_ASSET_INFO_SUCCESS:
    case UPLOAD_ASSET_IMAGE_SUCCESS:
    case UPDATE_ASSET_SUCCESS:
        return state
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('singleAsset', payload.data);
    case UPLOAD_NEW_ASSET_IMAGE_SUCCESS:
        return state
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('newAssetImage', payload.data);

    case DELETE_ASSET_SUCCESS:
        return state
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('singleAsset', {});

    case GET_ASSET_INFO_BY_IMEI_AND_DATE_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('assetInfo', payload.data);

    case GET_ASSET_MAP_ALERT_HISTORY_SUCCESS: {
        const assetMapAlertHistoryPagination = {};
        assetMapAlertHistoryPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        assetMapAlertHistoryPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        assetMapAlertHistoryPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        assetMapAlertHistoryPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('assetMapAlertHistoryPagination', assetMapAlertHistoryPagination)
            .set('assetMapAlertHistory', payload.data);
    }
    case GET_EVENT_TYPES_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('event_types', payload.data);
    case GET_ASSET_JOURNEY_SUCCESS: {
        const assetJourneyPagination = {};
        assetJourneyPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        assetJourneyPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        assetJourneyPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        assetJourneyPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('assetJourneyPagination', assetJourneyPagination)
            .set('assetJourney', payload.data);
    }
    case GET_ASSET_EVENT_HISTORY_SUCCESS: {
        const assetEventHistoryPagination = {};
        assetEventHistoryPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        assetEventHistoryPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        assetEventHistoryPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        assetEventHistoryPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('assetEventHistoryPagination', assetEventHistoryPagination)
            .set('assetEventHistory', payload.data);
    }
    case GET_ASSET_HISTORIC_VIDEO_SUCCESS: {
        const pagination = {};
        pagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        pagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        pagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        pagination.perPageCount = payload.headers['x-pagination-per-page'] || 16;
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('assetVideoHistoryPagination', pagination)
            .set('assetVideoHistory', payload.data);
    }
    case DELETE_SERVICE_ALERT_SUCCESS:
    case GET_ASSET_SERVICE_ALERTS_SUCCESS:
    case CREATE_SERVICE_ALERT_SUCCESS: {
        const serviceAlertsPagination = {};
        serviceAlertsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        serviceAlertsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        serviceAlertsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        serviceAlertsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('serviceAlertsPagination', serviceAlertsPagination)
            .set('serviceAlerts', payload.data);
    }
    case GET_ASSET_LIST_SUCCESS:
        return state
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('assetList', payload.data);
    case GET_BASIC_ASSET_LIST_SUCCESS:
        return state.set('isFetchingBasicAssetList', false)
            .set('basicAssetList', payload.data);
    case GET_ASSET_LIVE_VIDEO_URL_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('assetLiveVideos', payload.data);
    case QUEUE_BY_DEVICE_ID_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));

    case GET_DIVISIONS_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('divisions', payload.data);
    case GET_ALL_ASSET_OPTIONS_SUCCESS:
        return state.set('allOptions', payload.data).set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));
    case GET_ASSET_OPTIONS_SUCCESS:
        return state.set('options', payload.data).set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));
    case GET_ASSET_OPTIONS_STRICT_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('optionsStrict', payload.data);
    case GET_DIVISION_LIST_SUCCESS:
        return state.set('divisionList', payload.data).set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));

    case GET_VEHICLE_TYPES_SUCCESS:
        return state.set('vehicleTypes', payload.data)
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));

    case GET_ASSET_INFO_FAILURE: {
        return state.set('singleAsset', {})
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('error', payload);
    }

    case UPDATE_ASSET_JOURNEY_LOCATION_SUCCESS: {
        const { assetJourney } = state;

        if (payload && payload.payload && payload.payload.id) {
            const assetJourneyIndex = findIndex(assetJourney, (val) => val.id === payload.payload.id);
            if (assetJourneyIndex !== -1) {
                assetJourney[assetJourneyIndex].start_area_address = payload.response.data.start_area_address;
                assetJourney[assetJourneyIndex].end_area_address = payload.response.data.end_area_address;
            }
        }

        return state.set('assetJourney', assetJourney)
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));
    }

    case GET_CSV_ASSET_RECORDS_BY_DAY_SUCCESS:
        return state.set('isExportingCsv', false);

    case ASSET_SEARCH_FAILURE:
    case CREATE_ASSET_FAILURE:
    case DELETE_ASSET_FAILURE:
    case GET_ASSET_INFO_BY_IMEI_AND_DATE_FAILURE:
    case GET_ASSET_MAP_ALERT_HISTORY_FAILURE:
    case GET_EVENT_TYPES_FAILURE:
    case GET_ASSET_JOURNEY_FAILURE:
    case GET_ASSET_LIST_FAILURE:
    case GET_ASSET_EVENT_HISTORY_FAILURE:
    case GET_ASSET_HISTORIC_VIDEO_FAILURE:
    case GET_ASSET_SERVICE_ALERTS_FAILURE:
    case CREATE_SERVICE_ALERT_FAILURE:
    case UPLOAD_ASSET_IMAGE_FAILURE:
    case UPLOAD_NEW_ASSET_IMAGE_FAILURE:
    case GET_ASSET_LIVE_VIDEO_URL_FAILURE:
    case QUEUE_BY_DEVICE_ID_FAILURE:
    case UPDATE_ASSET_FAILURE:
    case GET_DIVISIONS_FAILURE:
    case GET_ALL_ASSET_OPTIONS_FAILURE:
    case GET_ASSET_OPTIONS_FAILURE:
    case GET_ASSET_OPTIONS_STRICT_FAILURE:
    case GET_DIVISION_LIST_FAILURE:
    case DELETE_SERVICE_ALERT_FAILURE:
    case GET_VEHICLE_TYPES_FAILURE:
    case UPDATE_ASSET_JOURNEY_LOCATION_FAILURE:
    case GET_VEHICLE_MAKES_FAILURE:
    case GET_VEHICLE_MAKE_MODELS_FAILURE:
    case DELETE_ASSET_DATA_FAILURE:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('error', payload);

    case GET_BASIC_ASSET_LIST_FAILURE:
        return state.set('isFetchingBasicAssetList', false)
            .set('error', payload);

    case GET_CSV_ASSET_RECORDS_BY_DAY_FAILURE:
        return state.set('isExportingCsv', false)
            .set('error', payload);

    case TOGGLE_ASSET_SELECTED:
        if (state.selectedAssets.includes(payload)) {
            return state.set('selectedAssets', removeAsset(state.selectedAssets, payload));
        }
        return state.set('selectedAssets', addAsset(state.selectedAssets, payload));
    case CLEAR_SELECTED_ASSETS:
        return state.set('selectedAssets', []);

    case SET_ASSET_JOURNEY_FILTERS:
        return state.set('assetJourneyFilters', payload);

    default:
        return state;
    }
}

export {
    assetReducer,
    assetInitialState,
};
