/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import {
    Select,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import './chartMultiDropDown.scss';

const { Option } = Select;

export default function () {
    const {
        firstOptionValue,
        secondOptionValue,
        thirdOptionValue,
        secondDropDownOptions,
        thirdDropDownOptions,
    } = this.state;

    const {
        onlyDriver,
    } = this.props;

    let firstSelectOptions = ['Assets', 'Drivers'];
    if (onlyDriver) {
        firstSelectOptions = ['Drivers'];
    }

    return (
        <div className="chart-multi-dropdown-block">
            <Select
                className="widget-custom-select custom-first"
                defaultValue={firstOptionValue}
                onChange={(value) => this.onFirstOptionChange(value)}
            >
                {firstSelectOptions.map((firstOption) => (
                    <Option key={`first-option-${firstOption}`} value={firstOption}>{firstOption}</Option>
                ))}
            </Select>

            <Select
                className="widget-custom-select"
                value={secondOptionValue}
                onChange={(value) => this.onSecondOptionChange(value)}
            >
                {secondDropDownOptions.map((secondOption) => (
                    <Option key={`second-option-${secondOption.id}`} value={secondOption.id}>{secondOption.name}</Option>
                ))}
            </Select>

            <Select
                className="widget-custom-select"
                value={thirdOptionValue}
                onChange={(value) => this.onThirdOptionChange(value)}
            >
                {thirdDropDownOptions.map((thirdOption) => (
                    <Option key={`third-option-${thirdOption.id}`} value={thirdOption.id}>{thirdOption.name}</Option>
                ))}
            </Select>
        </div>
    );
}
