/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Select, Button, Input } from 'antd';
import { isEmpty } from 'underscore';
import Icon from '../../../components/elements/Icon';
import './filterForm.css';

const FormItem = Form.Item;

function userFilterForm() {
    const { form, departments, users, divisions } = this.props;
    const { getFieldDecorator } = form;
    const { Option } = Select;
    return (
        <Form onSubmit={(this.handleSubmit)} className="login-form" layout="vertical">
            <Form.Item label="User name">
                {getFieldDecorator('name')(<Input placeholder="User name" />)}
            </Form.Item>
            <Form.Item label="Email">
                {getFieldDecorator('email')(<Input placeholder="Email" />)}
            </Form.Item>
            <Form.Item label="Status">
                {getFieldDecorator('status')(
                    <Select placeholder="Status">
                        <Option value="all">Any</Option>
                        <Option value="0">Inactive</Option>
                        <Option value="1">Active</Option>
                    </Select>,
                )}
            </Form.Item>
            <Form.Item label="Role">
                {getFieldDecorator('role')(
                    <Select placeholder="Role">
                        <Option value="all">Any</Option>
                        <Option value="user">User</Option>
                        <Option value="manager">Manager</Option>
                        <Option value="admin">Admin</Option>
                        <Option value="super-admin">Super Admin</Option>
                    </Select>,
                )}
            </Form.Item>
            <div className="mt-2 text-center">
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </div>
        </Form>
    );
}
function departmentFilterForm() {
    const { form, divisions, assetOptions } = this.props;
    const { getFieldDecorator } = form;
    const { Option } = Select;
    return (
        <Form onSubmit={this.handleSubmit} className="login-form" layout="vertical">
            <FormItem>
                {getFieldDecorator('divisions')(
                    <Select
                        showSearch
                        allowClear
                        mode="multiple"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Division"
                    >
                        {!isEmpty(divisions)
                            ? divisions.map((item) => (
                                <Option key={item.id} value={item.id}>{item.name}</Option>
                            ))
                            : null}
                    </Select>,
                )}
            </FormItem>
            {/* <FormItem>
                {getFieldDecorator('location')(<Input placeholder="ID" />)}
           </FormItem> */}
            <div className="mt-2 text-center">
                <Button htmlType="submit" type="primary">
                    Filter
                </Button>
            </div>
        </Form>
    );
}

export { userFilterForm, departmentFilterForm };
