import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker, Select, Button, Row, Col } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { isEmpty } from 'underscore';
import * as assetActions from '../../../core/asset/assetActions';
import * as driverActions from '../../../core/driver/driverActions';
import Icon from '../../../components/elements/Icon';
import { isoStandardDateTimeFormat } from '../../../core/utils/dateUtils';

dayjs.extend(customParseFormat);

const { Option } = Select;

class DriverJourneyHistoryFilterForm extends Component {
    componentDidMount() {
        const { form, actions, driverId } = this.props;
        form.validateFields();
        actions.getAssetOptionsRequest({ driverId });
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit, form, actions } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
                const { date_range } = values;
                if (!isEmpty(date_range)) {
                    const dateRange = [
                        date_range[0].format(isoStandardDateTimeFormat),
                        date_range[1].format(isoStandardDateTimeFormat),
                    ];
                    values.dateRange = dateRange;
                }
                actions.setDriverJourneyFilters(values);
            }
        });
    };

    render() {
        const { form, assetOptions, driverJourneyFilters, telematicsRetentionDays } = this.props;
        const { is_authorised, dateRange } = driverJourneyFilters;
        const { getFieldDecorator } = form;

        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Name/Reg">
                    {getFieldDecorator('assets')(
                        <Select
                            mode="multiple"
                            showSearch
                            allowClear
                            filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                            placeholder="Name/Reg"
                        >
                            {assetOptions.map((a) => (
                                <Option key={a.id} value={a.id}>
                                    {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                </Option>
                            ))}
                        </Select>,
                    )}
                </Form.Item>
                <Row>
                    <Col span={12}>
                        <Form.Item label="Date from">
                            {getFieldDecorator('date_from', { initialValue: dayjs() })(
                                <DatePicker 
                                    format="DD/MM/YYYY" 
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    allowClear={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Date to">
                            {getFieldDecorator('date_to', { initialValue: dayjs() })(
                                <DatePicker 
                                    format="DD/MM/YYYY" 
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    allowClear={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Journey events">
                    {getFieldDecorator('journey_event', {
                    })(
                        <Select
                            placeholder="Select Journey events"
                        >
                            <Select.Option value="all">All</Select.Option>
                            <Select.Option value="1">Yes</Select.Option>
                            <Select.Option value="0">No</Select.Option>
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Authorised">
                    {getFieldDecorator('is_authorised', {
                        initialValue: is_authorised !== null && is_authorised !== undefined ? is_authorised : 'all',
                    })(
                        <Select
                            placeholder="Select authorised journeys"
                        >
                            <Select.Option value="all">All</Select.Option>
                            <Select.Option value="1">Yes</Select.Option>
                            <Select.Option value="0">No</Select.Option>
                        </Select>,
                    )}
                </Form.Item>
                <div className="mt-2 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

DriverJourneyHistoryFilterForm.propTypes = {
    driverId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    driverJourneyFilters: PropTypes.object.isRequired,
    assetOptions: PropTypes.array,
};

DriverJourneyHistoryFilterForm.defaultProps = {
    assetOptions: [],
};

const DriverJourneyHistoryFilter = Form.create({ name: 'journey_filter_form' })(DriverJourneyHistoryFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        assetOptions: state.asset.options,
        driverJourneyFilters: state.driver.driverJourneyFilters,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriverJourneyHistoryFilter);
